:root {
  --im-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --im-color: #fff;
  --im-bg: #0b1016;
  --im-link-color: #5fb3fb;
  --im-link-hover-color: #5fb2fbae;
  --im-btn-color: #fff;
  --im-btn-bg: #0066dbd6;
  --im-btn-hover-bg: #0950a1e3;
  --im-btn-active-box-shadow: 0 0 0 .25em #1868fa3d;
  --im-btn-s-color: var(--im-bg);
  --im-btn-s-bg: #e1efffcc;
  --im-btn-s-hover-bg: #e1effff2;
  --im-btn-s-active-bg: #e1efff99;
  --im-overlay-bg: linear-gradient(14deg, #1e3861, #cedce91e);
}

div[data-service], div[data-service] .cll, div[data-service] .cll a, div[data-service] .cll button {
  all: initial;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  visibility: unset;
}

div[data-service] .cll :after, div[data-service] .cll iframe, div[data-service] .cll span, div[data-service]:before {
  all: unset;
  box-sizing: border-box;
}

div[data-service] .cll a, div[data-service] .cll button, div[data-service] .cll input {
  appearance: none;
  cursor: pointer;
  display: revert;
  outline: revert;
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: normal;
  overflow: hidden;
}

div[data-service] {
  background-color: #0b1016;
  background-color: var(--im-bg);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-family: var(--im-font-family);
  min-width: 18.75rem;
  max-width: 100%;
  min-height: 9.375rem;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

div[data-service]:before {
  content: "";
  padding-top: 56.25%;
  display: block;
}

div[data-service] .cll .c-ld {
  opacity: 0;
  visibility: hidden;
  bottom: 2em;
  right: 2.5em;
  transform: translateY(1.4em);
}

div[data-service] .cll .c-ld, div[data-service] .cll .c-ld:after {
  z-index: 1;
  border-radius: 100%;
  width: 1.25em;
  height: 1.25em;
  position: absolute;
}

div[data-service] .cll .c-ld:after {
  content: "";
  border: .25em solid #fff;
  border-top-color: #0000;
  animation: 1s linear infinite spin;
}

div[data-service].c-h-n .cll .c-ld {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

div[data-service].c-h-b .cll .c-ld {
  opacity: 0;
  visibility: hidden;
  transform: translateY(0);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

div[data-autoscale] {
  width: 100%;
  height: auto;
}

div[data-service] .cll .c-nt {
  color: var(--im-color);
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

div[data-service] .cll .c-bg {
  opacity: .5;
  position: absolute;
  inset: 0;
}

div[data-service] .cll .c-bg:before {
  background: linear-gradient(14deg, #1e3861, #cedce91e);
  background: var(--im-overlay-bg);
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

div[data-service] .cll .c-bg-i {
  opacity: 0;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

div[data-service] .cll .c-bg-i.loaded {
  opacity: 1;
}

div[data-service] .cll .c-tl {
  text-align: center;
  margin-bottom: .6em;
  font-size: 1.2em;
  font-weight: 600;
  display: block;
}

div[data-service].c-h-n .cll .c-bg {
  opacity: 1;
  transform: scale(1);
}

div[data-service] .cll .c-n-c {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

div[data-service] .cll .c-n-t {
  z-index: 1;
  max-width: 32em;
  margin: 0 auto;
  font-size: .87em;
  line-height: 1.4em;
  display: block;
  position: relative;
}

div[data-service] .cll .c-n-a {
  margin-top: 1.25em;
}

div[data-service] .cll .c-n-a, div[data-service] .cll .c-n-t {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

div[data-service] .cll .c-n-a button + button {
  margin-left: .8em;
}

div[data-service] .cll .c-t-cn {
  z-index: 1;
  padding: 0 .75em;
}

div[data-service] .cll .c-l-b, div[data-service] .cll .c-la-b {
  background: #0066dbd6;
  background: var(--im-btn-bg);
  color: var(--im-btn-color);
  border-radius: .3125em;
  justify-content: space-evenly;
  align-self: stretch;
  align-items: center;
  padding: .975em 1.1em;
  font-size: .77em;
  font-weight: 600;
  display: flex;
  position: relative;
  box-shadow: 0 .25em .75em #00000030;
}

div[data-service] .cll .c-l-b:before {
  border-bottom: .6em solid #0000;
  border-left: 1em solid #fff;
  border-left: 1em solid var(--im-btn-color);
  content: "";
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  border-top: .6em solid #0000;
  margin-right: .75em;
  display: block;
}

div[data-service] .cll .c-la-b {
  background: #e1efffcc;
  background: var(--im-btn-s-bg);
  color: var(--im-btn-s-color);
}

div[data-service] .cll .c-la-b:hover {
  background: #e1effff2;
  background: var(--im-btn-s-hover-bg);
}

div[data-service] .cll .c-l-b:hover {
  background: #0950a1e3;
  background: var(--im-btn-hover-bg);
}

div[data-service] .cll .c-la-b:active {
  background: #e1efff99;
  background: var(--im-btn-s-active-bg);
  transition: none !important;
}

div[data-service] .cll .c-l-b:active {
  box-shadow: 0 0 0 .25em #1868fa3d;
  box-shadow: var(--im-btn-active-box-shadow);
  transition: none !important;
}

div[data-service].c-h-n .cll .c-t-cn {
  opacity: 0;
  visibility: hidden;
  transition-duration: .2s;
  transform: translateY(-.7em);
}

div[data-service] .cll iframe {
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  background: #fff;
  border: none;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

div[data-service].c-h-b .cll iframe {
  opacity: 1;
  visibility: visible;
  height: 100% !important;
  display: block !important;
}

div[data-service] .cll .c-n-t a {
  border-bottom: 1px solid #5fb3fb;
  border-bottom: 1px solid var(--im-link-color);
  color: var(--im-link-color);
  text-decoration: none;
}

div[data-service] .cll .c-n-t a:hover {
  --im-link-color: var(--im-link-hover-color);
}

div[data-service][data-ratio="1:1"]:before {
  padding-top: 100%;
}

div[data-service][data-ratio="2:1"]:before {
  padding-top: 50%;
}

div[data-service][data-ratio="3:2"]:before {
  padding-top: 66.6667%;
}

div[data-service][data-ratio="5:2"]:before {
  padding-top: 40%;
}

div[data-service][data-ratio="4:3"]:before {
  padding-top: 75%;
}

div[data-service][data-ratio="16:9"]:before {
  padding-top: 56.25%;
}

div[data-service][data-ratio="16:10"]:before {
  padding-top: 62.5%;
}

div[data-service][data-ratio="20:9"]:before {
  padding-top: 45%;
}

div[data-service][data-ratio="21:9"]:before {
  padding-top: 42.8571%;
}

div[data-service][data-ratio="9:16"]:before {
  padding-top: 177.778%;
}

div[data-service][data-ratio="9:20"]:before {
  padding-top: 222.222%;
}

div[data-widget]:before {
  display: none;
}

div[data-widget].c-h-b .cll .c-ld {
  opacity: 0;
  visibility: hidden;
}

div[data-widget] .cll + *, div[data-widget] .cll > :last-child {
  height: 100% !important;
  margin: 0 !important;
}

div[data-service] [data-placeholder] {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  inset: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

div.c-an [data-placeholder] {
  animation: none;
}

div[data-service] [data-placeholder][data-visible] {
  color: var(--im-color);
  justify-content: center;
  align-items: center;
  animation: .2s .4s forwards fadeIn;
  display: flex;
}

div[data-service].show-ph [data-placeholder] {
  opacity: 1;
  visibility: visible;
}

div[data-service].show-ph .cll .c-ld {
  opacity: 0;
  visibility: hidden;
}

div.c-an .cll .c-bg, div.c-an .cll .c-bg:before, div.c-an .cll .c-ld, div.c-an .cll .c-n-t, div.c-an .cll .c-t-cn {
  transition: opacity .3s, visibility .3s, transform .4s;
}

div.c-an .cll .c-bg-i {
  transition: opacity .5s, transform .5s;
}

div.c-an[data-widget] {
  transition: opacity .3s, background-color .3s;
}

div.c-an .cll .c-l-b, div.c-an .cll .c-la-b {
  transition: opacity .3s, transform .3s cubic-bezier(.25, 1, .5, 1), visibility .3s, box-shadow .3s, background-color .3s;
}

div.c-an .cll .c-n-t a {
  transition: color .2s, border-color .2s;
}

div.c-an .cll iframe, div.c-an [data-placeholder] {
  transition: opacity .3s .1s, visibility .3s .1s;
}

:root {
  --mc-body-font-family: AvenirNext, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --mc-body-font-size: 1rem;
  --mc-body-font-weight: 300;
  --mc-body-line-height: 1.5;
  --mc-body-color: #222;
  --mc-body-bg: #f9f9f9;
  --mc-white: #fff;
  --mc-light: #f9f9f9;
  --mc-dark: #000;
  --mc-dim: #f6f6f6;
  --mc-global: #fad200;
  --mc-mindcurv: #fad200;
  --mc-initions: #f60;
  --mc-wysiwyg: #5000dc;
  --mc-eccelerate: #00c6f7;
  --mc-ccg: #a4e800;
  --mc-igniti: #0f9bbe;
  --mc-ccx: #ff005c;
  --g: max(20px, min(4vw, 6vh));
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--mc-body-font-family);
  font-size: var(--mc-body-font-size);
  font-weight: var(--mc-body-font-weight);
  line-height: var(--mc-body-line-height);
  color: var(--mc-body-color);
  text-align: var(--mc-body-text-align);
  background-color: var(--mc-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  background-color: currentColor;
  border: 0;
  margin: 1rem 0;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: 1.5em;
}

h3, .h3 {
  font-size: 1.3em;
}

h4, .h4 {
  font-size: 1.2em;
}

h5, .h5 {
  font-size: 1.1em;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title], abbr[data-bs-original-title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: 700;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: inherit;
  text-decoration: underline;
}

a:hover, a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  direction: ltr ;
  unicode-bidi: bidi-override;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: #d63384;
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #222;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #a09d93;
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.2em;
  font-weight: 400;
}

.display-1 {
  font-size: clamp(36px, 7.5vw, 200px);
  font-weight: 700;
  line-height: clamp(1em, 4.5vw, 200px);
}

.display-2 {
  font-size: clamp(32px, 5.35vw, 150px);
  font-weight: 700;
  line-height: clamp(1em, 4.5vw, 200px);
}

.display-3 {
  font-size: clamp(30px, 4.55vw, 125px);
  font-weight: 700;
  line-height: clamp(1em, 4.5vw, 200px);
}

.display-4 {
  font-size: clamp(30px, 3.4vw, 100px);
  font-weight: 700;
  line-height: clamp(1em, 4.5vw, 200px);
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #a09d93;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: #f9f9f9;
  border: 1px solid #e0e0e0;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #a09d93;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-sm, .container-xs {
  padding-right: var(--mc-gutter-x, calc(var(--g) * .5));
  padding-left: var(--mc-gutter-x, calc(var(--g) * .5));
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 90%;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 94%;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 96%;
  }
}

@media (width >= 1344px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 88%;
  }
}

@media (width >= 1600px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 80%;
  }
}

.row {
  --mc-gutter-x: var(--g);
  --mc-gutter-y: 0;
  margin-top: calc(-1 * var(--mc-gutter-y));
  margin-right: calc(-.5 * var(--mc-gutter-x));
  margin-left: calc(-.5 * var(--mc-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--mc-gutter-x) * .5);
  padding-left: calc(var(--mc-gutter-x) * .5);
  margin-top: var(--mc-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --mc-gutter-x: 0;
}

.g-0, .gy-0 {
  --mc-gutter-y: 0;
}

.g-1\/8, .gx-1\/8 {
  --mc-gutter-x: calc(var(--g) * .125);
}

.g-1\/8, .gy-1\/8 {
  --mc-gutter-y: calc(var(--g) * .125);
}

.g-1\/4, .gx-1\/4 {
  --mc-gutter-x: calc(var(--g) * .25);
}

.g-1\/4, .gy-1\/4 {
  --mc-gutter-y: calc(var(--g) * .25);
}

.g-1\/2, .gx-1\/2 {
  --mc-gutter-x: calc(var(--g) * .5);
}

.g-1\/2, .gy-1\/2 {
  --mc-gutter-y: calc(var(--g) * .5);
}

.g-1, .gx-1 {
  --mc-gutter-x: var(--g);
}

.g-1, .gy-1 {
  --mc-gutter-y: var(--g);
}

.g-2, .gx-2 {
  --mc-gutter-x: calc(var(--g) * 2);
}

.g-2, .gy-2 {
  --mc-gutter-y: calc(var(--g) * 2);
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --mc-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --mc-gutter-y: 0;
  }

  .g-sm-1\/8, .gx-sm-1\/8 {
    --mc-gutter-x: calc(var(--g) * .125);
  }

  .g-sm-1\/8, .gy-sm-1\/8 {
    --mc-gutter-y: calc(var(--g) * .125);
  }

  .g-sm-1\/4, .gx-sm-1\/4 {
    --mc-gutter-x: calc(var(--g) * .25);
  }

  .g-sm-1\/4, .gy-sm-1\/4 {
    --mc-gutter-y: calc(var(--g) * .25);
  }

  .g-sm-1\/2, .gx-sm-1\/2 {
    --mc-gutter-x: calc(var(--g) * .5);
  }

  .g-sm-1\/2, .gy-sm-1\/2 {
    --mc-gutter-y: calc(var(--g) * .5);
  }

  .g-sm-1, .gx-sm-1 {
    --mc-gutter-x: var(--g);
  }

  .g-sm-1, .gy-sm-1 {
    --mc-gutter-y: var(--g);
  }

  .g-sm-2, .gx-sm-2 {
    --mc-gutter-x: calc(var(--g) * 2);
  }

  .g-sm-2, .gy-sm-2 {
    --mc-gutter-y: calc(var(--g) * 2);
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --mc-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --mc-gutter-y: 0;
  }

  .g-md-1\/8, .gx-md-1\/8 {
    --mc-gutter-x: calc(var(--g) * .125);
  }

  .g-md-1\/8, .gy-md-1\/8 {
    --mc-gutter-y: calc(var(--g) * .125);
  }

  .g-md-1\/4, .gx-md-1\/4 {
    --mc-gutter-x: calc(var(--g) * .25);
  }

  .g-md-1\/4, .gy-md-1\/4 {
    --mc-gutter-y: calc(var(--g) * .25);
  }

  .g-md-1\/2, .gx-md-1\/2 {
    --mc-gutter-x: calc(var(--g) * .5);
  }

  .g-md-1\/2, .gy-md-1\/2 {
    --mc-gutter-y: calc(var(--g) * .5);
  }

  .g-md-1, .gx-md-1 {
    --mc-gutter-x: var(--g);
  }

  .g-md-1, .gy-md-1 {
    --mc-gutter-y: var(--g);
  }

  .g-md-2, .gx-md-2 {
    --mc-gutter-x: calc(var(--g) * 2);
  }

  .g-md-2, .gy-md-2 {
    --mc-gutter-y: calc(var(--g) * 2);
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --mc-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --mc-gutter-y: 0;
  }

  .g-lg-1\/8, .gx-lg-1\/8 {
    --mc-gutter-x: calc(var(--g) * .125);
  }

  .g-lg-1\/8, .gy-lg-1\/8 {
    --mc-gutter-y: calc(var(--g) * .125);
  }

  .g-lg-1\/4, .gx-lg-1\/4 {
    --mc-gutter-x: calc(var(--g) * .25);
  }

  .g-lg-1\/4, .gy-lg-1\/4 {
    --mc-gutter-y: calc(var(--g) * .25);
  }

  .g-lg-1\/2, .gx-lg-1\/2 {
    --mc-gutter-x: calc(var(--g) * .5);
  }

  .g-lg-1\/2, .gy-lg-1\/2 {
    --mc-gutter-y: calc(var(--g) * .5);
  }

  .g-lg-1, .gx-lg-1 {
    --mc-gutter-x: var(--g);
  }

  .g-lg-1, .gy-lg-1 {
    --mc-gutter-y: var(--g);
  }

  .g-lg-2, .gx-lg-2 {
    --mc-gutter-x: calc(var(--g) * 2);
  }

  .g-lg-2, .gy-lg-2 {
    --mc-gutter-y: calc(var(--g) * 2);
  }
}

@media (width >= 1344px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --mc-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --mc-gutter-y: 0;
  }

  .g-xl-1\/8, .gx-xl-1\/8 {
    --mc-gutter-x: calc(var(--g) * .125);
  }

  .g-xl-1\/8, .gy-xl-1\/8 {
    --mc-gutter-y: calc(var(--g) * .125);
  }

  .g-xl-1\/4, .gx-xl-1\/4 {
    --mc-gutter-x: calc(var(--g) * .25);
  }

  .g-xl-1\/4, .gy-xl-1\/4 {
    --mc-gutter-y: calc(var(--g) * .25);
  }

  .g-xl-1\/2, .gx-xl-1\/2 {
    --mc-gutter-x: calc(var(--g) * .5);
  }

  .g-xl-1\/2, .gy-xl-1\/2 {
    --mc-gutter-y: calc(var(--g) * .5);
  }

  .g-xl-1, .gx-xl-1 {
    --mc-gutter-x: var(--g);
  }

  .g-xl-1, .gy-xl-1 {
    --mc-gutter-y: var(--g);
  }

  .g-xl-2, .gx-xl-2 {
    --mc-gutter-x: calc(var(--g) * 2);
  }

  .g-xl-2, .gy-xl-2 {
    --mc-gutter-y: calc(var(--g) * 2);
  }
}

@media (width >= 1600px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --mc-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --mc-gutter-y: 0;
  }

  .g-xxl-1\/8, .gx-xxl-1\/8 {
    --mc-gutter-x: calc(var(--g) * .125);
  }

  .g-xxl-1\/8, .gy-xxl-1\/8 {
    --mc-gutter-y: calc(var(--g) * .125);
  }

  .g-xxl-1\/4, .gx-xxl-1\/4 {
    --mc-gutter-x: calc(var(--g) * .25);
  }

  .g-xxl-1\/4, .gy-xxl-1\/4 {
    --mc-gutter-y: calc(var(--g) * .25);
  }

  .g-xxl-1\/2, .gx-xxl-1\/2 {
    --mc-gutter-x: calc(var(--g) * .5);
  }

  .g-xxl-1\/2, .gy-xxl-1\/2 {
    --mc-gutter-y: calc(var(--g) * .5);
  }

  .g-xxl-1, .gx-xxl-1 {
    --mc-gutter-x: var(--g);
  }

  .g-xxl-1, .gy-xxl-1 {
    --mc-gutter-y: var(--g);
  }

  .g-xxl-2, .gx-xxl-2 {
    --mc-gutter-x: calc(var(--g) * 2);
  }

  .g-xxl-2, .gy-xxl-2 {
    --mc-gutter-y: calc(var(--g) * 2);
  }
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.modal {
  z-index: 1055;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  pointer-events: none;
  width: auto;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: 24px;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1050;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid;
  border-top-left-radius: 23px;
  border-top-right-radius: 23px;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-header .btn-close {
  margin: -.5rem -.5rem -.5rem auto;
  padding: .5rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid;
  border-bottom-right-radius: 23px;
  border-bottom-left-radius: 23px;
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  padding: .75rem;
  display: flex;
}

.modal-footer > * {
  margin: .25rem;
}

@media (width >= 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (width >= 1344px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 1343.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (width <= 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.offcanvas {
  z-index: 1045;
  color: var(--mc-menu-color, #fff);
  visibility: hidden;
  background-color: var(--mc-menu-bg, #000);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .5;
}

.offcanvas-header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.offcanvas-header .btn-close {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
  margin-right: -.5rem;
  padding: .5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  border-right: 1px solid #0003;
  width: 50%;
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas-end {
  border-left: 1px solid #0003;
  width: 50%;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas-top {
  border-bottom: 1px solid #0003;
  height: 30vh;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas-bottom {
  border-top: 1px solid #0003;
  height: 30vh;
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  color: inherit;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: inherit;
}

.nav-link.disabled {
  color: #a09d93;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #e0e0e0;
}

.nav-tabs .nav-link {
  background: none;
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  margin-bottom: -1px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #f6f6f6 #f6f6f6 #e0e0e0;
}

.nav-tabs .nav-link.disabled {
  color: #a09d93;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #888;
  background-color: #f9f9f9;
  border-color: #e0e0e0 #e0e0e0 #f9f9f9;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.breadcrumb {
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  color: #a09d93;
  content: var(--mc-breadcrumb-divider, "/");
  padding-right: .5rem;
}

.breadcrumb-item.active {
  color: #a09d93;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-light {
  border-color: #f9f9f9 #222 !important;
}

.border-dark {
  border-color: #000 #f9f9f9 !important;
}

.border-dim {
  border-color: #f6f6f6 #222 !important;
}

.border-global, .border-mindcurv {
  border-color: #fad200 #222 !important;
}

.border-initions {
  border-color: #f60 #fff !important;
}

.border-wysiwyg {
  border-color: #5000dc #fff !important;
}

.border-eccelerate {
  border-color: #00c6f7 #fff !important;
}

.border-ccg {
  border-color: #a4e800 #222 !important;
}

.border-igniti {
  border-color: #0f9bbe #fff !important;
}

.border-ccx {
  border-color: #ff005c #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1\/8 {
  gap: calc(var(--g) * .125) !important;
}

.gap-1\/4 {
  gap: calc(var(--g) * .25) !important;
}

.gap-1\/2 {
  gap: calc(var(--g) * .5) !important;
}

.gap-1 {
  gap: var(--g) !important;
}

.gap-2 {
  gap: calc(var(--g) * 2) !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1\/8 {
  margin: calc(var(--g) * .125) !important;
}

.m-1\/4 {
  margin: calc(var(--g) * .25) !important;
}

.m-1\/2 {
  margin: calc(var(--g) * .5) !important;
}

.m-1 {
  margin: var(--g) !important;
}

.m-2 {
  margin: calc(var(--g) * 2) !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1\/8 {
  margin-right: calc(var(--g) * .125) !important;
  margin-left: calc(var(--g) * .125) !important;
}

.mx-1\/4 {
  margin-right: calc(var(--g) * .25) !important;
  margin-left: calc(var(--g) * .25) !important;
}

.mx-1\/2 {
  margin-right: calc(var(--g) * .5) !important;
  margin-left: calc(var(--g) * .5) !important;
}

.mx-1 {
  margin-right: var(--g) !important;
  margin-left: var(--g) !important;
}

.mx-2 {
  margin-right: calc(var(--g) * 2) !important;
  margin-left: calc(var(--g) * 2) !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1\/8 {
  margin-top: calc(var(--g) * .125) !important;
  margin-bottom: calc(var(--g) * .125) !important;
}

.my-1\/4 {
  margin-top: calc(var(--g) * .25) !important;
  margin-bottom: calc(var(--g) * .25) !important;
}

.my-1\/2 {
  margin-top: calc(var(--g) * .5) !important;
  margin-bottom: calc(var(--g) * .5) !important;
}

.my-1 {
  margin-top: var(--g) !important;
  margin-bottom: var(--g) !important;
}

.my-2 {
  margin-top: calc(var(--g) * 2) !important;
  margin-bottom: calc(var(--g) * 2) !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1\/8 {
  margin-top: calc(var(--g) * .125) !important;
}

.mt-1\/4 {
  margin-top: calc(var(--g) * .25) !important;
}

.mt-1\/2 {
  margin-top: calc(var(--g) * .5) !important;
}

.mt-1 {
  margin-top: var(--g) !important;
}

.mt-2 {
  margin-top: calc(var(--g) * 2) !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1\/8 {
  margin-right: calc(var(--g) * .125) !important;
}

.me-1\/4 {
  margin-right: calc(var(--g) * .25) !important;
}

.me-1\/2 {
  margin-right: calc(var(--g) * .5) !important;
}

.me-1 {
  margin-right: var(--g) !important;
}

.me-2 {
  margin-right: calc(var(--g) * 2) !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1\/8 {
  margin-bottom: calc(var(--g) * .125) !important;
}

.mb-1\/4 {
  margin-bottom: calc(var(--g) * .25) !important;
}

.mb-1\/2 {
  margin-bottom: calc(var(--g) * .5) !important;
}

.mb-1 {
  margin-bottom: var(--g) !important;
}

.mb-2 {
  margin-bottom: calc(var(--g) * 2) !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1\/8 {
  margin-left: calc(var(--g) * .125) !important;
}

.ms-1\/4 {
  margin-left: calc(var(--g) * .25) !important;
}

.ms-1\/2 {
  margin-left: calc(var(--g) * .5) !important;
}

.ms-1 {
  margin-left: var(--g) !important;
}

.ms-2 {
  margin-left: calc(var(--g) * 2) !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1\/8 {
  padding: calc(var(--g) * .125) !important;
}

.p-1\/4 {
  padding: calc(var(--g) * .25) !important;
}

.p-1\/2 {
  padding: calc(var(--g) * .5) !important;
}

.p-1 {
  padding: var(--g) !important;
}

.p-2 {
  padding: calc(var(--g) * 2) !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1\/8 {
  padding-right: calc(var(--g) * .125) !important;
  padding-left: calc(var(--g) * .125) !important;
}

.px-1\/4 {
  padding-right: calc(var(--g) * .25) !important;
  padding-left: calc(var(--g) * .25) !important;
}

.px-1\/2 {
  padding-right: calc(var(--g) * .5) !important;
  padding-left: calc(var(--g) * .5) !important;
}

.px-1 {
  padding-right: var(--g) !important;
  padding-left: var(--g) !important;
}

.px-2 {
  padding-right: calc(var(--g) * 2) !important;
  padding-left: calc(var(--g) * 2) !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1\/8 {
  padding-top: calc(var(--g) * .125) !important;
  padding-bottom: calc(var(--g) * .125) !important;
}

.py-1\/4 {
  padding-top: calc(var(--g) * .25) !important;
  padding-bottom: calc(var(--g) * .25) !important;
}

.py-1\/2 {
  padding-top: calc(var(--g) * .5) !important;
  padding-bottom: calc(var(--g) * .5) !important;
}

.py-1 {
  padding-top: var(--g) !important;
  padding-bottom: var(--g) !important;
}

.py-2 {
  padding-top: calc(var(--g) * 2) !important;
  padding-bottom: calc(var(--g) * 2) !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1\/8 {
  padding-top: calc(var(--g) * .125) !important;
}

.pt-1\/4 {
  padding-top: calc(var(--g) * .25) !important;
}

.pt-1\/2 {
  padding-top: calc(var(--g) * .5) !important;
}

.pt-1 {
  padding-top: var(--g) !important;
}

.pt-2 {
  padding-top: calc(var(--g) * 2) !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1\/8 {
  padding-right: calc(var(--g) * .125) !important;
}

.pe-1\/4 {
  padding-right: calc(var(--g) * .25) !important;
}

.pe-1\/2 {
  padding-right: calc(var(--g) * .5) !important;
}

.pe-1 {
  padding-right: var(--g) !important;
}

.pe-2 {
  padding-right: calc(var(--g) * 2) !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1\/8 {
  padding-bottom: calc(var(--g) * .125) !important;
}

.pb-1\/4 {
  padding-bottom: calc(var(--g) * .25) !important;
}

.pb-1\/2 {
  padding-bottom: calc(var(--g) * .5) !important;
}

.pb-1 {
  padding-bottom: var(--g) !important;
}

.pb-2 {
  padding-bottom: calc(var(--g) * 2) !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1\/8 {
  padding-left: calc(var(--g) * .125) !important;
}

.ps-1\/4 {
  padding-left: calc(var(--g) * .25) !important;
}

.ps-1\/2 {
  padding-left: calc(var(--g) * .5) !important;
}

.ps-1 {
  padding-left: var(--g) !important;
}

.ps-2 {
  padding-left: calc(var(--g) * 2) !important;
}

.font-monospace {
  font-family: var(--mc-font-monospace) !important;
}

.fs-1 {
  font-size:  !important;
}

.fs-2 {
  font-size: 1.5em !important;
}

.fs-3 {
  font-size: 1.3em !important;
}

.fs-4 {
  font-size: 1.2em !important;
}

.fs-5 {
  font-size: 1.1em !important;
}

.fs-6 {
  font-size:  !important;
}

.fs-sm {
  font-size: .875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold, .fw-bolder {
  font-weight: 700 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 1.7 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-black {
  --mc-text-opacity: 1;
  color: rgba(var(--mc-black-rgb), var(--mc-text-opacity)) !important;
}

.text-white {
  --mc-text-opacity: 1;
  color: rgba(var(--mc-white-rgb), var(--mc-text-opacity)) !important;
}

.text-body {
  --mc-text-opacity: 1;
  color: rgba(var(--mc-body-color-rgb), var(--mc-text-opacity)) !important;
}

.text-muted {
  --mc-text-opacity: 1;
  color: #a09d93 !important;
}

.text-black-50 {
  --mc-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --mc-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --mc-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --mc-text-opacity: .25;
}

.text-opacity-50 {
  --mc-text-opacity: .5;
}

.text-opacity-75 {
  --mc-text-opacity: .75;
}

.text-opacity-100 {
  --mc-text-opacity: 1;
}

.bg-black {
  --mc-bg-opacity: 1;
  background-color: rgba(var(--mc-black-rgb), var(--mc-bg-opacity)) !important;
}

.bg-white {
  --mc-bg-opacity: 1;
  background-color: rgba(var(--mc-white-rgb), var(--mc-bg-opacity)) !important;
}

.bg-body {
  --mc-bg-opacity: 1;
  background-color: rgba(var(--mc-body-bg-rgb), var(--mc-bg-opacity)) !important;
}

.bg-transparent {
  --mc-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --mc-bg-opacity: .1;
}

.bg-opacity-25 {
  --mc-bg-opacity: .25;
}

.bg-opacity-50 {
  --mc-bg-opacity: .5;
}

.bg-opacity-75 {
  --mc-bg-opacity: .75;
}

.bg-opacity-100 {
  --mc-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--mc-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: .2rem !important;
}

.rounded-2 {
  border-radius: .25rem !important;
}

.rounded-3 {
  border-radius: 24px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-end {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-start {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1\/8 {
    gap: calc(var(--g) * .125) !important;
  }

  .gap-sm-1\/4 {
    gap: calc(var(--g) * .25) !important;
  }

  .gap-sm-1\/2 {
    gap: calc(var(--g) * .5) !important;
  }

  .gap-sm-1 {
    gap: var(--g) !important;
  }

  .gap-sm-2 {
    gap: calc(var(--g) * 2) !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1\/8 {
    margin: calc(var(--g) * .125) !important;
  }

  .m-sm-1\/4 {
    margin: calc(var(--g) * .25) !important;
  }

  .m-sm-1\/2 {
    margin: calc(var(--g) * .5) !important;
  }

  .m-sm-1 {
    margin: var(--g) !important;
  }

  .m-sm-2 {
    margin: calc(var(--g) * 2) !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
    margin-left: calc(var(--g) * .125) !important;
  }

  .mx-sm-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
    margin-left: calc(var(--g) * .25) !important;
  }

  .mx-sm-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
    margin-left: calc(var(--g) * .5) !important;
  }

  .mx-sm-1 {
    margin-right: var(--g) !important;
    margin-left: var(--g) !important;
  }

  .mx-sm-2 {
    margin-right: calc(var(--g) * 2) !important;
    margin-left: calc(var(--g) * 2) !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .my-sm-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .my-sm-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .my-sm-1 {
    margin-top: var(--g) !important;
    margin-bottom: var(--g) !important;
  }

  .my-sm-2 {
    margin-top: calc(var(--g) * 2) !important;
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
  }

  .mt-sm-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
  }

  .mt-sm-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
  }

  .mt-sm-1 {
    margin-top: var(--g) !important;
  }

  .mt-sm-2 {
    margin-top: calc(var(--g) * 2) !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
  }

  .me-sm-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
  }

  .me-sm-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
  }

  .me-sm-1 {
    margin-right: var(--g) !important;
  }

  .me-sm-2 {
    margin-right: calc(var(--g) * 2) !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1\/8 {
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .mb-sm-1\/4 {
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .mb-sm-1\/2 {
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .mb-sm-1 {
    margin-bottom: var(--g) !important;
  }

  .mb-sm-2 {
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1\/8 {
    margin-left: calc(var(--g) * .125) !important;
  }

  .ms-sm-1\/4 {
    margin-left: calc(var(--g) * .25) !important;
  }

  .ms-sm-1\/2 {
    margin-left: calc(var(--g) * .5) !important;
  }

  .ms-sm-1 {
    margin-left: var(--g) !important;
  }

  .ms-sm-2 {
    margin-left: calc(var(--g) * 2) !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1\/8 {
    padding: calc(var(--g) * .125) !important;
  }

  .p-sm-1\/4 {
    padding: calc(var(--g) * .25) !important;
  }

  .p-sm-1\/2 {
    padding: calc(var(--g) * .5) !important;
  }

  .p-sm-1 {
    padding: var(--g) !important;
  }

  .p-sm-2 {
    padding: calc(var(--g) * 2) !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
    padding-left: calc(var(--g) * .125) !important;
  }

  .px-sm-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
    padding-left: calc(var(--g) * .25) !important;
  }

  .px-sm-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
    padding-left: calc(var(--g) * .5) !important;
  }

  .px-sm-1 {
    padding-right: var(--g) !important;
    padding-left: var(--g) !important;
  }

  .px-sm-2 {
    padding-right: calc(var(--g) * 2) !important;
    padding-left: calc(var(--g) * 2) !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .py-sm-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .py-sm-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .py-sm-1 {
    padding-top: var(--g) !important;
    padding-bottom: var(--g) !important;
  }

  .py-sm-2 {
    padding-top: calc(var(--g) * 2) !important;
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
  }

  .pt-sm-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
  }

  .pt-sm-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
  }

  .pt-sm-1 {
    padding-top: var(--g) !important;
  }

  .pt-sm-2 {
    padding-top: calc(var(--g) * 2) !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
  }

  .pe-sm-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
  }

  .pe-sm-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
  }

  .pe-sm-1 {
    padding-right: var(--g) !important;
  }

  .pe-sm-2 {
    padding-right: calc(var(--g) * 2) !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1\/8 {
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .pb-sm-1\/4 {
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .pb-sm-1\/2 {
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .pb-sm-1 {
    padding-bottom: var(--g) !important;
  }

  .pb-sm-2 {
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1\/8 {
    padding-left: calc(var(--g) * .125) !important;
  }

  .ps-sm-1\/4 {
    padding-left: calc(var(--g) * .25) !important;
  }

  .ps-sm-1\/2 {
    padding-left: calc(var(--g) * .5) !important;
  }

  .ps-sm-1 {
    padding-left: var(--g) !important;
  }

  .ps-sm-2 {
    padding-left: calc(var(--g) * 2) !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1\/8 {
    gap: calc(var(--g) * .125) !important;
  }

  .gap-md-1\/4 {
    gap: calc(var(--g) * .25) !important;
  }

  .gap-md-1\/2 {
    gap: calc(var(--g) * .5) !important;
  }

  .gap-md-1 {
    gap: var(--g) !important;
  }

  .gap-md-2 {
    gap: calc(var(--g) * 2) !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1\/8 {
    margin: calc(var(--g) * .125) !important;
  }

  .m-md-1\/4 {
    margin: calc(var(--g) * .25) !important;
  }

  .m-md-1\/2 {
    margin: calc(var(--g) * .5) !important;
  }

  .m-md-1 {
    margin: var(--g) !important;
  }

  .m-md-2 {
    margin: calc(var(--g) * 2) !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
    margin-left: calc(var(--g) * .125) !important;
  }

  .mx-md-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
    margin-left: calc(var(--g) * .25) !important;
  }

  .mx-md-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
    margin-left: calc(var(--g) * .5) !important;
  }

  .mx-md-1 {
    margin-right: var(--g) !important;
    margin-left: var(--g) !important;
  }

  .mx-md-2 {
    margin-right: calc(var(--g) * 2) !important;
    margin-left: calc(var(--g) * 2) !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .my-md-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .my-md-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .my-md-1 {
    margin-top: var(--g) !important;
    margin-bottom: var(--g) !important;
  }

  .my-md-2 {
    margin-top: calc(var(--g) * 2) !important;
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
  }

  .mt-md-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
  }

  .mt-md-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
  }

  .mt-md-1 {
    margin-top: var(--g) !important;
  }

  .mt-md-2 {
    margin-top: calc(var(--g) * 2) !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
  }

  .me-md-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
  }

  .me-md-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
  }

  .me-md-1 {
    margin-right: var(--g) !important;
  }

  .me-md-2 {
    margin-right: calc(var(--g) * 2) !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1\/8 {
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .mb-md-1\/4 {
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .mb-md-1\/2 {
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .mb-md-1 {
    margin-bottom: var(--g) !important;
  }

  .mb-md-2 {
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1\/8 {
    margin-left: calc(var(--g) * .125) !important;
  }

  .ms-md-1\/4 {
    margin-left: calc(var(--g) * .25) !important;
  }

  .ms-md-1\/2 {
    margin-left: calc(var(--g) * .5) !important;
  }

  .ms-md-1 {
    margin-left: var(--g) !important;
  }

  .ms-md-2 {
    margin-left: calc(var(--g) * 2) !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1\/8 {
    padding: calc(var(--g) * .125) !important;
  }

  .p-md-1\/4 {
    padding: calc(var(--g) * .25) !important;
  }

  .p-md-1\/2 {
    padding: calc(var(--g) * .5) !important;
  }

  .p-md-1 {
    padding: var(--g) !important;
  }

  .p-md-2 {
    padding: calc(var(--g) * 2) !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
    padding-left: calc(var(--g) * .125) !important;
  }

  .px-md-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
    padding-left: calc(var(--g) * .25) !important;
  }

  .px-md-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
    padding-left: calc(var(--g) * .5) !important;
  }

  .px-md-1 {
    padding-right: var(--g) !important;
    padding-left: var(--g) !important;
  }

  .px-md-2 {
    padding-right: calc(var(--g) * 2) !important;
    padding-left: calc(var(--g) * 2) !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .py-md-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .py-md-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .py-md-1 {
    padding-top: var(--g) !important;
    padding-bottom: var(--g) !important;
  }

  .py-md-2 {
    padding-top: calc(var(--g) * 2) !important;
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
  }

  .pt-md-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
  }

  .pt-md-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
  }

  .pt-md-1 {
    padding-top: var(--g) !important;
  }

  .pt-md-2 {
    padding-top: calc(var(--g) * 2) !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
  }

  .pe-md-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
  }

  .pe-md-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
  }

  .pe-md-1 {
    padding-right: var(--g) !important;
  }

  .pe-md-2 {
    padding-right: calc(var(--g) * 2) !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1\/8 {
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .pb-md-1\/4 {
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .pb-md-1\/2 {
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .pb-md-1 {
    padding-bottom: var(--g) !important;
  }

  .pb-md-2 {
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1\/8 {
    padding-left: calc(var(--g) * .125) !important;
  }

  .ps-md-1\/4 {
    padding-left: calc(var(--g) * .25) !important;
  }

  .ps-md-1\/2 {
    padding-left: calc(var(--g) * .5) !important;
  }

  .ps-md-1 {
    padding-left: var(--g) !important;
  }

  .ps-md-2 {
    padding-left: calc(var(--g) * 2) !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1\/8 {
    gap: calc(var(--g) * .125) !important;
  }

  .gap-lg-1\/4 {
    gap: calc(var(--g) * .25) !important;
  }

  .gap-lg-1\/2 {
    gap: calc(var(--g) * .5) !important;
  }

  .gap-lg-1 {
    gap: var(--g) !important;
  }

  .gap-lg-2 {
    gap: calc(var(--g) * 2) !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1\/8 {
    margin: calc(var(--g) * .125) !important;
  }

  .m-lg-1\/4 {
    margin: calc(var(--g) * .25) !important;
  }

  .m-lg-1\/2 {
    margin: calc(var(--g) * .5) !important;
  }

  .m-lg-1 {
    margin: var(--g) !important;
  }

  .m-lg-2 {
    margin: calc(var(--g) * 2) !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
    margin-left: calc(var(--g) * .125) !important;
  }

  .mx-lg-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
    margin-left: calc(var(--g) * .25) !important;
  }

  .mx-lg-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
    margin-left: calc(var(--g) * .5) !important;
  }

  .mx-lg-1 {
    margin-right: var(--g) !important;
    margin-left: var(--g) !important;
  }

  .mx-lg-2 {
    margin-right: calc(var(--g) * 2) !important;
    margin-left: calc(var(--g) * 2) !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .my-lg-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .my-lg-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .my-lg-1 {
    margin-top: var(--g) !important;
    margin-bottom: var(--g) !important;
  }

  .my-lg-2 {
    margin-top: calc(var(--g) * 2) !important;
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
  }

  .mt-lg-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
  }

  .mt-lg-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
  }

  .mt-lg-1 {
    margin-top: var(--g) !important;
  }

  .mt-lg-2 {
    margin-top: calc(var(--g) * 2) !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
  }

  .me-lg-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
  }

  .me-lg-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
  }

  .me-lg-1 {
    margin-right: var(--g) !important;
  }

  .me-lg-2 {
    margin-right: calc(var(--g) * 2) !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1\/8 {
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .mb-lg-1\/4 {
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .mb-lg-1\/2 {
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .mb-lg-1 {
    margin-bottom: var(--g) !important;
  }

  .mb-lg-2 {
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1\/8 {
    margin-left: calc(var(--g) * .125) !important;
  }

  .ms-lg-1\/4 {
    margin-left: calc(var(--g) * .25) !important;
  }

  .ms-lg-1\/2 {
    margin-left: calc(var(--g) * .5) !important;
  }

  .ms-lg-1 {
    margin-left: var(--g) !important;
  }

  .ms-lg-2 {
    margin-left: calc(var(--g) * 2) !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1\/8 {
    padding: calc(var(--g) * .125) !important;
  }

  .p-lg-1\/4 {
    padding: calc(var(--g) * .25) !important;
  }

  .p-lg-1\/2 {
    padding: calc(var(--g) * .5) !important;
  }

  .p-lg-1 {
    padding: var(--g) !important;
  }

  .p-lg-2 {
    padding: calc(var(--g) * 2) !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
    padding-left: calc(var(--g) * .125) !important;
  }

  .px-lg-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
    padding-left: calc(var(--g) * .25) !important;
  }

  .px-lg-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
    padding-left: calc(var(--g) * .5) !important;
  }

  .px-lg-1 {
    padding-right: var(--g) !important;
    padding-left: var(--g) !important;
  }

  .px-lg-2 {
    padding-right: calc(var(--g) * 2) !important;
    padding-left: calc(var(--g) * 2) !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .py-lg-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .py-lg-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .py-lg-1 {
    padding-top: var(--g) !important;
    padding-bottom: var(--g) !important;
  }

  .py-lg-2 {
    padding-top: calc(var(--g) * 2) !important;
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
  }

  .pt-lg-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
  }

  .pt-lg-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
  }

  .pt-lg-1 {
    padding-top: var(--g) !important;
  }

  .pt-lg-2 {
    padding-top: calc(var(--g) * 2) !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
  }

  .pe-lg-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
  }

  .pe-lg-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
  }

  .pe-lg-1 {
    padding-right: var(--g) !important;
  }

  .pe-lg-2 {
    padding-right: calc(var(--g) * 2) !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1\/8 {
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .pb-lg-1\/4 {
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .pb-lg-1\/2 {
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .pb-lg-1 {
    padding-bottom: var(--g) !important;
  }

  .pb-lg-2 {
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1\/8 {
    padding-left: calc(var(--g) * .125) !important;
  }

  .ps-lg-1\/4 {
    padding-left: calc(var(--g) * .25) !important;
  }

  .ps-lg-1\/2 {
    padding-left: calc(var(--g) * .5) !important;
  }

  .ps-lg-1 {
    padding-left: var(--g) !important;
  }

  .ps-lg-2 {
    padding-left: calc(var(--g) * 2) !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1344px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1\/8 {
    gap: calc(var(--g) * .125) !important;
  }

  .gap-xl-1\/4 {
    gap: calc(var(--g) * .25) !important;
  }

  .gap-xl-1\/2 {
    gap: calc(var(--g) * .5) !important;
  }

  .gap-xl-1 {
    gap: var(--g) !important;
  }

  .gap-xl-2 {
    gap: calc(var(--g) * 2) !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1\/8 {
    margin: calc(var(--g) * .125) !important;
  }

  .m-xl-1\/4 {
    margin: calc(var(--g) * .25) !important;
  }

  .m-xl-1\/2 {
    margin: calc(var(--g) * .5) !important;
  }

  .m-xl-1 {
    margin: var(--g) !important;
  }

  .m-xl-2 {
    margin: calc(var(--g) * 2) !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
    margin-left: calc(var(--g) * .125) !important;
  }

  .mx-xl-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
    margin-left: calc(var(--g) * .25) !important;
  }

  .mx-xl-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
    margin-left: calc(var(--g) * .5) !important;
  }

  .mx-xl-1 {
    margin-right: var(--g) !important;
    margin-left: var(--g) !important;
  }

  .mx-xl-2 {
    margin-right: calc(var(--g) * 2) !important;
    margin-left: calc(var(--g) * 2) !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .my-xl-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .my-xl-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .my-xl-1 {
    margin-top: var(--g) !important;
    margin-bottom: var(--g) !important;
  }

  .my-xl-2 {
    margin-top: calc(var(--g) * 2) !important;
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
  }

  .mt-xl-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
  }

  .mt-xl-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
  }

  .mt-xl-1 {
    margin-top: var(--g) !important;
  }

  .mt-xl-2 {
    margin-top: calc(var(--g) * 2) !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
  }

  .me-xl-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
  }

  .me-xl-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
  }

  .me-xl-1 {
    margin-right: var(--g) !important;
  }

  .me-xl-2 {
    margin-right: calc(var(--g) * 2) !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1\/8 {
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .mb-xl-1\/4 {
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .mb-xl-1\/2 {
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .mb-xl-1 {
    margin-bottom: var(--g) !important;
  }

  .mb-xl-2 {
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1\/8 {
    margin-left: calc(var(--g) * .125) !important;
  }

  .ms-xl-1\/4 {
    margin-left: calc(var(--g) * .25) !important;
  }

  .ms-xl-1\/2 {
    margin-left: calc(var(--g) * .5) !important;
  }

  .ms-xl-1 {
    margin-left: var(--g) !important;
  }

  .ms-xl-2 {
    margin-left: calc(var(--g) * 2) !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1\/8 {
    padding: calc(var(--g) * .125) !important;
  }

  .p-xl-1\/4 {
    padding: calc(var(--g) * .25) !important;
  }

  .p-xl-1\/2 {
    padding: calc(var(--g) * .5) !important;
  }

  .p-xl-1 {
    padding: var(--g) !important;
  }

  .p-xl-2 {
    padding: calc(var(--g) * 2) !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
    padding-left: calc(var(--g) * .125) !important;
  }

  .px-xl-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
    padding-left: calc(var(--g) * .25) !important;
  }

  .px-xl-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
    padding-left: calc(var(--g) * .5) !important;
  }

  .px-xl-1 {
    padding-right: var(--g) !important;
    padding-left: var(--g) !important;
  }

  .px-xl-2 {
    padding-right: calc(var(--g) * 2) !important;
    padding-left: calc(var(--g) * 2) !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .py-xl-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .py-xl-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .py-xl-1 {
    padding-top: var(--g) !important;
    padding-bottom: var(--g) !important;
  }

  .py-xl-2 {
    padding-top: calc(var(--g) * 2) !important;
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
  }

  .pt-xl-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
  }

  .pt-xl-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
  }

  .pt-xl-1 {
    padding-top: var(--g) !important;
  }

  .pt-xl-2 {
    padding-top: calc(var(--g) * 2) !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
  }

  .pe-xl-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
  }

  .pe-xl-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
  }

  .pe-xl-1 {
    padding-right: var(--g) !important;
  }

  .pe-xl-2 {
    padding-right: calc(var(--g) * 2) !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1\/8 {
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .pb-xl-1\/4 {
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .pb-xl-1\/2 {
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .pb-xl-1 {
    padding-bottom: var(--g) !important;
  }

  .pb-xl-2 {
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1\/8 {
    padding-left: calc(var(--g) * .125) !important;
  }

  .ps-xl-1\/4 {
    padding-left: calc(var(--g) * .25) !important;
  }

  .ps-xl-1\/2 {
    padding-left: calc(var(--g) * .5) !important;
  }

  .ps-xl-1 {
    padding-left: var(--g) !important;
  }

  .ps-xl-2 {
    padding-left: calc(var(--g) * 2) !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1600px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1\/8 {
    gap: calc(var(--g) * .125) !important;
  }

  .gap-xxl-1\/4 {
    gap: calc(var(--g) * .25) !important;
  }

  .gap-xxl-1\/2 {
    gap: calc(var(--g) * .5) !important;
  }

  .gap-xxl-1 {
    gap: var(--g) !important;
  }

  .gap-xxl-2 {
    gap: calc(var(--g) * 2) !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1\/8 {
    margin: calc(var(--g) * .125) !important;
  }

  .m-xxl-1\/4 {
    margin: calc(var(--g) * .25) !important;
  }

  .m-xxl-1\/2 {
    margin: calc(var(--g) * .5) !important;
  }

  .m-xxl-1 {
    margin: var(--g) !important;
  }

  .m-xxl-2 {
    margin: calc(var(--g) * 2) !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
    margin-left: calc(var(--g) * .125) !important;
  }

  .mx-xxl-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
    margin-left: calc(var(--g) * .25) !important;
  }

  .mx-xxl-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
    margin-left: calc(var(--g) * .5) !important;
  }

  .mx-xxl-1 {
    margin-right: var(--g) !important;
    margin-left: var(--g) !important;
  }

  .mx-xxl-2 {
    margin-right: calc(var(--g) * 2) !important;
    margin-left: calc(var(--g) * 2) !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .my-xxl-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .my-xxl-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .my-xxl-1 {
    margin-top: var(--g) !important;
    margin-bottom: var(--g) !important;
  }

  .my-xxl-2 {
    margin-top: calc(var(--g) * 2) !important;
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1\/8 {
    margin-top: calc(var(--g) * .125) !important;
  }

  .mt-xxl-1\/4 {
    margin-top: calc(var(--g) * .25) !important;
  }

  .mt-xxl-1\/2 {
    margin-top: calc(var(--g) * .5) !important;
  }

  .mt-xxl-1 {
    margin-top: var(--g) !important;
  }

  .mt-xxl-2 {
    margin-top: calc(var(--g) * 2) !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1\/8 {
    margin-right: calc(var(--g) * .125) !important;
  }

  .me-xxl-1\/4 {
    margin-right: calc(var(--g) * .25) !important;
  }

  .me-xxl-1\/2 {
    margin-right: calc(var(--g) * .5) !important;
  }

  .me-xxl-1 {
    margin-right: var(--g) !important;
  }

  .me-xxl-2 {
    margin-right: calc(var(--g) * 2) !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1\/8 {
    margin-bottom: calc(var(--g) * .125) !important;
  }

  .mb-xxl-1\/4 {
    margin-bottom: calc(var(--g) * .25) !important;
  }

  .mb-xxl-1\/2 {
    margin-bottom: calc(var(--g) * .5) !important;
  }

  .mb-xxl-1 {
    margin-bottom: var(--g) !important;
  }

  .mb-xxl-2 {
    margin-bottom: calc(var(--g) * 2) !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1\/8 {
    margin-left: calc(var(--g) * .125) !important;
  }

  .ms-xxl-1\/4 {
    margin-left: calc(var(--g) * .25) !important;
  }

  .ms-xxl-1\/2 {
    margin-left: calc(var(--g) * .5) !important;
  }

  .ms-xxl-1 {
    margin-left: var(--g) !important;
  }

  .ms-xxl-2 {
    margin-left: calc(var(--g) * 2) !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1\/8 {
    padding: calc(var(--g) * .125) !important;
  }

  .p-xxl-1\/4 {
    padding: calc(var(--g) * .25) !important;
  }

  .p-xxl-1\/2 {
    padding: calc(var(--g) * .5) !important;
  }

  .p-xxl-1 {
    padding: var(--g) !important;
  }

  .p-xxl-2 {
    padding: calc(var(--g) * 2) !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
    padding-left: calc(var(--g) * .125) !important;
  }

  .px-xxl-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
    padding-left: calc(var(--g) * .25) !important;
  }

  .px-xxl-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
    padding-left: calc(var(--g) * .5) !important;
  }

  .px-xxl-1 {
    padding-right: var(--g) !important;
    padding-left: var(--g) !important;
  }

  .px-xxl-2 {
    padding-right: calc(var(--g) * 2) !important;
    padding-left: calc(var(--g) * 2) !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .py-xxl-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .py-xxl-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .py-xxl-1 {
    padding-top: var(--g) !important;
    padding-bottom: var(--g) !important;
  }

  .py-xxl-2 {
    padding-top: calc(var(--g) * 2) !important;
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1\/8 {
    padding-top: calc(var(--g) * .125) !important;
  }

  .pt-xxl-1\/4 {
    padding-top: calc(var(--g) * .25) !important;
  }

  .pt-xxl-1\/2 {
    padding-top: calc(var(--g) * .5) !important;
  }

  .pt-xxl-1 {
    padding-top: var(--g) !important;
  }

  .pt-xxl-2 {
    padding-top: calc(var(--g) * 2) !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1\/8 {
    padding-right: calc(var(--g) * .125) !important;
  }

  .pe-xxl-1\/4 {
    padding-right: calc(var(--g) * .25) !important;
  }

  .pe-xxl-1\/2 {
    padding-right: calc(var(--g) * .5) !important;
  }

  .pe-xxl-1 {
    padding-right: var(--g) !important;
  }

  .pe-xxl-2 {
    padding-right: calc(var(--g) * 2) !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1\/8 {
    padding-bottom: calc(var(--g) * .125) !important;
  }

  .pb-xxl-1\/4 {
    padding-bottom: calc(var(--g) * .25) !important;
  }

  .pb-xxl-1\/2 {
    padding-bottom: calc(var(--g) * .5) !important;
  }

  .pb-xxl-1 {
    padding-bottom: var(--g) !important;
  }

  .pb-xxl-2 {
    padding-bottom: calc(var(--g) * 2) !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1\/8 {
    padding-left: calc(var(--g) * .125) !important;
  }

  .ps-xxl-1\/4 {
    padding-left: calc(var(--g) * .25) !important;
  }

  .ps-xxl-1\/2 {
    padding-left: calc(var(--g) * .5) !important;
  }

  .ps-xxl-1 {
    padding-left: var(--g) !important;
  }

  .ps-xxl-2 {
    padding-left: calc(var(--g) * 2) !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

:root {
  view-transition-name: none;
}

html:has(.teaser)::view-transition-new(*):only-child {
  animation-name: scale-in, -ua-mix-blend-mode-plus-lighter;
}

html:has(.teaser)::view-transition-old(*):only-child {
  animation-name: scale-out, -ua-mix-blend-mode-plus-lighter;
}

@keyframes scale-out {
  to {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale(.8);
  }
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext-Light.woff2");
  src: url("../fonts/AvenirNext-Light.woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext-Regular.woff2");
  src: url("../fonts/AvenirNext-Regular.woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext-Medium.woff2");
  src: url("../fonts/AvenirNext-Medium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext-Demi.woff2");
  src: url("../fonts/AvenirNext-Demi.woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: AvenirNext;
  src: url("../fonts/AvenirNext-Bold.woff2");
  src: url("../fonts/AvenirNext-Bold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Sentinel;
  src: url("../fonts/Sentinel-Light_Web.woff2");
  src: url("../fonts/Sentinel-Light_Web.woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Sentinel;
  src: url("../fonts/Sentinel-BookItalic_Web.woff2");
  src: url("../fonts/Sentinel-BookItalic_Web.woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

.btn {
  color: #222;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 50px;
  padding: 13px 20px;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #222;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65;
}

.btn-link {
  color: inherit;
  font-weight: 400;
  text-decoration: underline;
}

.btn-link:hover {
  color: inherit;
  text-decoration: none;
}

.btn-link:focus {
  text-decoration: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #a09d93;
}

.btn-lg {
  border-radius: 50px;
  padding: 20px 27px;
  font-size: 1.25rem;
}

.btn-sm {
  border-radius: 50px;
  padding: .25rem .5rem;
  font-size: .625rem;
}

.btn {
  color: var(--mc-btn-color);
  background-color: var(--mc-btn-bg);
  border-color: var(--mc-btn-bg);
  letter-spacing: .025em;
  text-transform: uppercase;
}

.btn:hover {
  color: var(--mc-btn-hover-color);
  background-color: var(--mc-btn-hover-bg);
  border-color: var(--mc-btn-hover-bg);
}

.btn-gray {
  color: #5b5b5d;
  background-color: #ececeb;
  border-color: #ececeb;
}

.btn-gray:hover {
  color: #000;
  background-color: #efefee;
  border-color: #eeeeed;
}

.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000;
  background-color: #efefee;
  border-color: #eeeeed;
  box-shadow: 0 0 0 .25rem #d6d6d680;
}

.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #000;
  background-color: #f0f0ef;
  border-color: #eeeeed;
}

.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #d6d6d680;
}

.btn-gray:disabled, .btn-gray.disabled {
  color: #000;
  background-color: #ececeb;
  border-color: #ececeb;
}

.btn-outline {
  color: #000;
  background-color: #0000;
  border-color: #000;
}

.btn-outline.halo-wysiwyg:hover {
  color: #fff;
  background-color: #5000dc;
  border-color: #5000dc;
}

.btn-outline.halo-igniti:hover {
  color: #fff;
  background-color: #0f9bbe;
  border-color: #0f9bbe;
}

.btn-outline.halo-ccg:hover {
  color: #fff;
  background-color: #a4e800;
  border-color: #a4e800;
}

.btn-outline.halo-eccelerate:hover {
  color: #fff;
  background-color: #00c6f7;
  border-color: #00c6f7;
}

.btn-outline.halo-mindcurv:hover {
  color: #000;
  background-color: #fad200;
  border-color: #fad200;
}

.btn-outline.halo-initions:hover {
  color: #fff;
  background-color: #f60;
  border-color: #f60;
}

.form-label {
  margin-bottom: .5rem;
  font-size: .75em;
  font-weight: 500;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: .8125em;
  padding-bottom: .8125em;
  font-weight: 500;
  line-height: 1;
}

.col-form-label-lg {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .875rem;
}

.form-text {
  color: #a09d93;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  font-family: inherit;
  font-size: inherit;
  color: #222;
  appearance: none;
  background-color: #f0f0f0;
  background-clip: padding-box;
  border: 0 solid #d9d8d4;
  border-radius: 0;
  width: 100%;
  padding: .8125em 1.25em;
  font-weight: 300;
  line-height: 1;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #222;
  background-color: #f0f0f0;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-control::-webkit-date-and-time-value {
  height: 1em;
}

.form-control::placeholder {
  color: #a09d93;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #f6f6f6;
}

.form-control::file-selector-button {
  margin: -.8125em -1.25em;
  color: #222;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #f6f6f6;
  border-inline-end-width: 0;
  border-radius: 0;
  margin-inline-end: 1.25em;
  padding: .8125em 1.25em;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #eaeaea;
}

.form-control::-webkit-file-upload-button {
  color: #222;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #f6f6f6;
  border-inline-end-width: 0;
  border-radius: 0;
  margin: -.8125em -1.25em;
  margin-inline-end: 1.25em;
  padding: .8125em 1.25em;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #eaeaea;
}

.form-control-plaintext {
  color: #222;
  border: solid #0000;
  background-color: #0000;
  border-width: 0;
  width: 100%;
  margin-bottom: 0;
  padding: .8125em 0;
  line-height: 1;
  display: block;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: .2rem;
  min-height: calc(1em + .5rem);
  padding: .25rem .5rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-sm::file-selector-button {
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem;
  padding: .25rem .5rem;
}

.form-control-lg {
  border-radius: 24px;
  min-height: calc(1em + 40px);
  padding: 20px 27px;
  font-size: 1.25rem;
}

.form-control-lg::file-selector-button {
  margin: -20px -27px;
  margin-inline-end: 27px;
  padding: 20px 27px;
}

.form-control-lg::file-selector-button {
  margin: -20px -27px;
  margin-inline-end: 27px;
  padding: 20px 27px;
}

textarea.form-control {
  min-height: 2.625em;
}

textarea.form-control-sm {
  min-height: calc(1em + .5rem);
}

textarea.form-control-lg {
  min-height: calc(1em + 40px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: .8125em;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0;
  height: 1em;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0;
  height: 1em;
}

.form-select {
  -moz-padding-start: calc(1.25em - 3px);
  font-family: inherit;
  font-size: inherit;
  color: #222;
  appearance: none;
  background-color: #f0f0f0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235b5b5d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 1.25em center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 0 solid #d9d8d4;
  border-radius: 0;
  width: 100%;
  padding: .8125em 3.75em .8125em 1.25em;
  font-weight: 300;
  line-height: 1;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1.25em;
}

.form-select:disabled {
  background-color: #f6f6f6;
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #222;
}

.form-select-sm {
  border-radius: .2rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: 24px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 27px;
  font-size: 1.25rem;
}

.form-check {
  min-height: 1.5rem;
  margin-bottom: .125rem;
  padding-left: 1.75em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.75em;
}

.form-check-input {
  vertical-align: top;
  appearance: none;
  color-adjust: exact;
  background-color: #f0f0f0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #00000040;
  width: 1.25em;
  height: 1.25em;
  margin-top: .125em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #0d6efd;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.5rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 .25rem #0d6efd40;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 .25rem #0d6efd40;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: #e0e0e0;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: #e0e0e0;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #d4cfcc;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #d4cfcc;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-select {
  height: 3.5rem;
  line-height: 1.25;
}

.form-floating > label {
  pointer-events: none;
  transform-origin: 0 0;
  border: 0 solid #0000;
  height: 100%;
  padding: 1rem 1.25em;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control {
  padding: 1rem 1.25em;
}

.form-floating > .form-control::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  font-size: inherit;
  color: #222;
  text-align: center;
  white-space: nowrap;
  background-color: #f6f6f6;
  border: 0 solid #d9d8d4;
  border-radius: 0;
  align-items: center;
  padding: .8125em 1.25em;
  font-weight: 300;
  line-height: 1;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: 24px;
  padding: 20px 27px;
  font-size: 1.25rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 5em;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.valid-feedback {
  color: #198754;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #198754e6;
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .65625em center;
  background-repeat: no-repeat;
  background-size: 1.3125em 1.3125em;
  border-color: #198754;
  padding-right: 2.625em;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right .65625em top .65625em;
  padding-right: 2.625em;
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235b5b5d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.25em center, right 3.75em center;
  background-size: 16px 12px, 1.3125em 1.3125em;
  padding-right: 6.875em;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .25rem #19875440;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated .input-group .form-select:valid, .input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated .input-group .form-select:valid:focus, .input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  color: #ff0063;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #000;
  background-color: #ff0063e6;
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='12' height='12' fill='%23ff0063'%3e%3cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z'/%3e%3c/svg%3e");
  background-position: right .65625em center;
  background-repeat: no-repeat;
  background-size: 1.3125em 1.3125em;
  border-color: #ff0063;
  padding-right: 2.625em;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #ff0063;
  box-shadow: 0 0 0 .25rem #ff006340;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right .65625em top .65625em;
  padding-right: 2.625em;
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ff0063;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%235b5b5d' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='12' height='12' fill='%23ff0063'%3e%3cpath d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z'/%3e%3c/svg%3e");
  background-position: right 1.25em center, right 3.75em center;
  background-size: 16px 12px, 1.3125em 1.3125em;
  padding-right: 6.875em;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #ff0063;
  box-shadow: 0 0 0 .25rem #ff006340;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ff0063;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #ff0063;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .25rem #ff006340;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #ff0063;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated .input-group .form-select:invalid, .input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated .input-group .form-select:invalid:focus, .input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.form-label {
  text-transform: uppercase;
  letter-spacing: .15em;
}

.form-control[type="file"] {
  line-height: 1.6;
}

.invalid-feedback {
  display: inline-block !important;
}

.mcg-form {
  font-weight: 500;
}

.mcg-form .form-label {
  letter-spacing: .1em;
  font-size: 10px;
  font-weight: 600;
}

.mcg-form .form-check-label {
  font-size: 16px;
  font-weight: 400;
}

.mcg-form .form-control {
  padding: .4166em 1.25em;
}

.mcg-form .form-control, .mcg-form .form-select {
  color: inherit;
  opacity: 1;
  background-color: #00000008;
  border-radius: 8px;
}

.mcg-form .form-control:focus, .mcg-form .form-select:focus {
  box-shadow: none;
  background-color: #0000000f;
}

.mcg-form .form-select option {
  color: #222;
}

.mcg-form .form-select {
  line-height: .917;
}

.mcg-form .form-control::file-selector-button {
  color: inherit;
}

.mcg-form .form-check {
  line-height: 1em;
}

.mcg-form .form-check-input {
  color: inherit;
  opacity: 1;
  background-color: #0000000f;
  border-width: 0;
  border-radius: 2px;
  width: 1em;
  height: 1em;
}

.mcg-form .form-check-input:checked[type="checkbox"] {
  background-color: #0000001f;
}

.mcg-form .form-check-input:focus[type="checkbox"] {
  box-shadow: none;
}

.mcg-form .form-control::file-selector-button {
  background-color: #0000000f;
}

.mcg-form .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #0000001f;
}

.mcg-form textarea.form-control {
  line-height: 1.21;
}

.mcg-form .mandatory-label {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  width: auto;
  display: inline;
  position: absolute;
  top: 33px;
  right: -35px;
  transform: rotate(180deg);
}

.mcg-form.section .row {
  position: relative;
}

.mcg-form.theme-igniti .form-control, .mcg-form.theme-igniti .form-select, .mcg-form.theme-dark .form-control, .mcg-form.theme-dark .form-select, .mcg-form.theme-ccg .form-control, .mcg-form.theme-ccg .form-select, .mcg-form.theme-wysiwyg .form-control, .mcg-form.theme-wysiwyg .form-select, .mcg-form.theme-ccx .form-control, .mcg-form.theme-ccx .form-select {
  background-color: #ffffff1f;
}

.mcg-form.theme-igniti .form-select, .mcg-form.theme-dark .form-select, .mcg-form.theme-ccg .form-select, .mcg-form.theme-wysiwyg .form-select, .mcg-form.theme-ccx .form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.mcg-form.theme-igniti .form-check-input, .mcg-form.theme-dark .form-check-input, .mcg-form.theme-ccg .form-check-input, .mcg-form.theme-wysiwyg .form-check-input, .mcg-form.theme-ccx .form-check-input {
  background-color: #ffffff3d;
}

.mcg-form.theme-igniti .form-check-input:checked[type="checkbox"], .mcg-form.theme-dark .form-check-input:checked[type="checkbox"], .mcg-form.theme-ccg .form-check-input:checked[type="checkbox"], .mcg-form.theme-wysiwyg .form-check-input:checked[type="checkbox"], .mcg-form.theme-ccx .form-check-input:checked[type="checkbox"] {
  background-color: #ffffff5c;
}

.mcg-form.theme-igniti .form-control:focus, .mcg-form.theme-igniti .form-select:focus, .mcg-form.theme-dark .form-control:focus, .mcg-form.theme-dark .form-select:focus, .mcg-form.theme-ccg .form-control:focus, .mcg-form.theme-ccg .form-select:focus, .mcg-form.theme-wysiwyg .form-control:focus, .mcg-form.theme-wysiwyg .form-select:focus, .mcg-form.theme-ccx .form-control:focus, .mcg-form.theme-ccx .form-select:focus {
  background-color: #ffffff3d;
}

.mcg-form.theme-igniti .form-control::file-selector-button {
  background-color: #ffffff3d;
}

.mcg-form.theme-dark .form-control::file-selector-button {
  background-color: #ffffff3d;
}

.mcg-form.theme-ccg .form-control::file-selector-button {
  background-color: #ffffff3d;
}

.mcg-form.theme-wysiwyg .form-control::file-selector-button {
  background-color: #ffffff3d;
}

.mcg-form.theme-ccx .form-control::file-selector-button {
  background-color: #ffffff3d;
}

.mcg-form.theme-igniti .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ffffff5c;
}

.mcg-form.theme-dark .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ffffff5c;
}

.mcg-form.theme-ccg .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ffffff5c;
}

.mcg-form.theme-wysiwyg .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ffffff5c;
}

.mcg-form.theme-ccx .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #ffffff5c;
}

[class*="theme-"] {
  color: var(--mc-color);
  background-color: var(--mc-bg);
}

.theme-white {
  --mc-bg: #fff;
  --mc-color: #222;
  --mc-btn-bg: #000;
  --mc-btn-color: #fff;
  --mc-btn-hover-bg: #fad200;
  --mc-btn-hover-color: #000;
}

.theme-white .page-header {
  --mc-bg: #fad200;
  --mc-menu-bg: #000;
  --mc-menu-color: #f9f9f9;
}

.theme-light {
  --mc-bg: #f9f9f9;
  --mc-color: #222;
  --mc-btn-bg: #000;
  --mc-btn-color: #fff;
  --mc-btn-hover-bg: #fad200;
  --mc-btn-hover-color: #000;
}

.theme-light .page-header {
  --mc-bg: #fad200;
  --mc-menu-bg: #000;
  --mc-menu-color: #f9f9f9;
}

.theme-dark {
  --mc-bg: #000;
  --mc-color: #f9f9f9;
  --mc-btn-bg: #fff;
  --mc-btn-color: #000;
  --mc-btn-hover-bg: #fad200;
  --mc-btn-hover-color: #000;
}

.theme-dark .page-header {
  --mc-bg: #fad200;
  --mc-menu-bg: #f9f9f9;
  --mc-menu-color: #222;
}

.theme-dim {
  --mc-bg: #f6f6f6;
  --mc-color: #222;
  --mc-btn-bg: #000;
  --mc-btn-color: #fff;
  --mc-btn-hover-bg: #fad200;
  --mc-btn-hover-color: #000;
}

.theme-dim .page-header {
  --mc-bg: #fad200;
  --mc-menu-bg: #000;
  --mc-menu-color: #f9f9f9;
}

.theme-global, .theme-mindcurv {
  --mc-bg: #fad200;
  --mc-color: #222;
  --mc-btn-bg: #000;
  --mc-btn-color: #fff;
  --mc-btn-hover-bg: #fff;
  --mc-btn-hover-color: #000;
}

.theme-initions {
  --mc-bg: #f60;
  --mc-color: #fff;
  --mc-btn-bg: #fff;
  --mc-btn-color: #f60;
  --mc-btn-hover-bg: #ff9b14;
  --mc-btn-hover-color: #fff;
}

.theme-wysiwyg {
  --mc-bg: #5000dc;
  --mc-color: #fff;
  --mc-btn-bg: #fff;
  --mc-btn-color: #5000dc;
  --mc-btn-hover-bg: #00c6f7;
  --mc-btn-hover-color: #fff;
}

.theme-eccelerate {
  --mc-bg: #00c6f7;
  --mc-color: #fff;
  --mc-btn-bg: #fff;
  --mc-btn-color: #00c6f7;
  --mc-btn-hover-bg: #224456;
  --mc-btn-hover-color: #fff;
}

.theme-ccg {
  --mc-bg: #a4e800;
  --mc-color: #222;
  --mc-btn-bg: #000;
  --mc-btn-color: #fff;
  --mc-btn-hover-bg: #fff;
  --mc-btn-hover-color: #000;
}

.theme-igniti {
  --mc-bg: #0f9bbe;
  --mc-color: #fff;
  --mc-btn-bg: #fff;
  --mc-btn-color: #0f9bbe;
  --mc-btn-hover-bg: #224456;
  --mc-btn-hover-color: #fff;
}

.theme-ccx {
  --mc-bg: #ff005c;
  --mc-color: #fff;
  --mc-btn-bg: #fff;
  --mc-btn-color: #ff005c;
  --mc-btn-hover-bg: #ff80ae;
  --mc-btn-hover-color: #fff;
}

.theme-dark .topline, .theme-initions .topline, .theme-wysiwyg .topline, .theme-ccg .topline, .theme-igniti .topline {
  opacity: .66;
}

.page {
  --mc-body-font-size: clamp(1rem, 1.25vw, 24px);
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

.page-header {
  font-size: max(1rem, 1.5vw);
  font-weight: 500;
}

.page-header.position-absolute {
  z-index: 1;
}

.page-header .nav-link {
  color: inherit;
}

.page-menu.offcanvas {
  border-left: none;
  width: 100%;
  top: 0;
  right: 0;
  transform: translateY(100%);
}

@media (width >= 768px) {
  .page-menu.offcanvas {
    width: 50%;
    transform: translateX(100%);
  }
}

.page-menu.offcanvas.show {
  transform: none;
}

.page-menu .offcanvas-body {
  padding: var(--g);
  flex-direction: column-reverse;
  display: flex;
}

@media (width >= 768px) {
  .page-menu .offcanvas-body {
    flex-direction: column;
  }
}

.page-menu .nav {
  flex-direction: inherit;
}

.page-menu .nav-link:hover {
  text-underline-offset: .1em;
  text-decoration: underline;
}

.page-menu .nav-link.active {
  color: var(--mc-bg);
}

.page-menu .nav-link.active:hover {
  -webkit-text-decoration-color: var(--mc-menu-color, var(--mc-menu-color, #fff));
  text-decoration-color: var(--mc-menu-color, var(--mc-menu-color, #fff));
}

.page-menu-primary {
  margin-bottom: auto;
}

@media (width >= 768px) {
  .page-menu-primary {
    margin-top: auto;
  }
}

.page-menu-primary .nav-item {
  margin-bottom: calc(var(--g) * .5);
}

.page-menu-secondary {
  margin-top: auto;
  margin-bottom: calc(var(--g) * 2);
}

@media (width >= 768px) {
  .page-menu-secondary {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.page-main {
  flex-grow: 1;
}

.page-main a:not([class]) {
  text-underline-offset: .25em;
  font-weight: 500;
}

.page-main a:not([class]):hover {
  -webkit-text-decoration: underline dashed;
  text-decoration: underline dashed;
}

.page-footer {
  font-size: 14px;
  line-height: 2;
}

.page-footer a {
  color: inherit;
}

.page-footer .nav {
  margin-left: -1rem;
}

.page-footer .nav-label, .page-footer .nav-link {
  color: #888;
}

.page-footer .nav-link:hover {
  color: var(--mc-color);
}

.page-footer .nav-link.active {
  font-weight: 600;
}

.navbar {
  padding-top: calc(var(--g) * .75);
  padding-bottom: calc(var(--g) * .75);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-xs, .navbar > .container-sm, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  font-size: 1.25rem;
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: 50px;
  padding: max(10px, 1vw);
  font-size: 1.25rem;
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 .25rem;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--mc-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler, .navbar-expand-sm .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-sm .offcanvas-top, .navbar-expand-sm .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler, .navbar-expand-md .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-md .offcanvas-top, .navbar-expand-md .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler, .navbar-expand-lg .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-lg .offcanvas-top, .navbar-expand-lg .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1344px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler, .navbar-expand-xl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xl .offcanvas-top, .navbar-expand-xl .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler, .navbar-expand-xxl .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: inherit;
    z-index: 1000;
    background-color: #0000;
    border-left: 0;
    border-right: 0;
    flex-grow: 1;
    transition: none;
    bottom: 0;
    transform: none;
    visibility: visible !important;
  }

  .navbar-expand-xxl .offcanvas-top, .navbar-expand-xxl .offcanvas-bottom {
    border-top: 0;
    border-bottom: 0;
    height: auto;
  }

  .navbar-expand-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: 2vw;
  padding-right: 2vw;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler, .navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  z-index: 1000;
  background-color: #0000;
  border-left: 0;
  border-right: 0;
  flex-grow: 1;
  transition: none;
  bottom: 0;
  transform: none;
  visibility: visible !important;
}

.navbar-expand .offcanvas-top, .navbar-expand .offcanvas-bottom {
  border-top: 0;
  border-bottom: 0;
  height: auto;
}

.navbar-expand .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus, .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #000;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link.active {
  color: #000;
}

.navbar-light .navbar-toggler {
  color: #000;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: unset;
}

.navbar-light .navbar-text, .navbar-light .navbar-text a, .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: #000;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: unset;
}

.navbar-dark .navbar-text, .navbar-dark .navbar-text a, .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.navbar-nav-expanded {
  flex-direction: row;
}

.navbar-nav-expanded .dropdown-menu {
  position: absolute;
}

.navbar-nav-expanded .nav-link {
  padding-left: 2vw;
  padding-right: 2vw;
}

.navbar-brand {
  width: 20%;
  min-width: 160px;
}

.navbar-brand img {
  max-width: 100%;
  height: auto;
}

.navbar-brand, .navbar-brand:hover {
  color: inherit;
}

.navbar-nav {
  margin-top: calc(var(--g) * -.4);
}

@media (width >= 992px) {
  .navbar-nav {
    margin-top: calc(var(--g) * -.35);
  }
}

.navbar-toggler {
  z-index: 1045;
  bottom: calc(var(--g) * .75);
  color: #aaa;
  background-color: var(--mc-menu-bg, #000);
}

@supports ((-webkit-backdrop-filter: none)) or ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .navbar-toggler {
    -webkit-backdrop-filter: invert() grayscale() contrast(20) blur(10px) contrast(30);
    backdrop-filter: invert() grayscale() contrast(20) blur(10px) contrast(30);
    background-color: #0000;
  }
}

@media (width >= 768px) {
  .navbar-toggler {
    bottom: auto;
  }
}

.navbar-toggler.active {
  color: var(--mc-color);
  background-color: var(--mc-bg);
  z-index: 1046;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}

.navbar-toggler-icon {
  position: relative;
}

.navbar-toggler-icon:before, .navbar-toggler-icon:after {
  content: "";
  background-color: currentColor;
  width: 80%;
  height: 2px;
  transition: transform .15s ease-out;
  position: absolute;
  left: 10%;
}

.navbar-toggler-icon:before {
  top: 25%;
}

.navbar-toggler-icon:after {
  bottom: 25%;
}

.navbar-toggler.active .navbar-toggler-icon:before {
  transform: translateY(.35em)rotate(135deg);
}

.navbar-toggler.active .navbar-toggler-icon:after {
  transform: translateY(-.3em)rotate(-135deg);
}

.navbar-link-underline {
  transform: translateX(calc(var(--x, 0) * 1px)) scaleX(var(--w, 0));
  transform-origin: 0;
  background-color: currentColor;
  width: 1px;
  height: 2px;
  transition: transform .25s cubic-bezier(.34, 1.56, .64, 1);
  position: absolute;
  bottom: 0;
  left: 0;
}

.list {
  border-top: 1px solid;
  padding: 0;
  list-style: none;
}

.list > li, .list > dt, .list > dd, .list > .list-item {
  padding: calc(var(--g) / 2) 0;
  border-bottom: 1px solid;
}

dl.list {
  grid-template-columns: auto auto;
  display: grid;
}

dl.list > dd, dl.list > dt, dl.list > .list-item {
  width: 100%;
}

dl.list > dt {
  font-weight: inherit;
}

dl.list > dd {
  text-align: right;
  padding-left: calc(var(--g) / 4);
  align-self: end;
  margin-bottom: 0;
  margin-left: auto;
  font-weight: 600;
}

video::-webkit-media-controls-panel {
  -webkit-backdrop-filter: blur(5px) brightness(.6);
  backdrop-filter: blur(5px) brightness(.6);
  margin: 0 auto calc(var(--g) / 2);
  background: none;
  border-radius: 5em;
  flex: 0 auto;
  align-self: end;
  min-width: 225px;
  height: min-content;
  box-shadow: 0 2px 1em #00000026;
}

video::-webkit-media-controls-current-time-display {
  display: none;
}

video::-webkit-media-controls-time-remaining-display {
  display: none;
}

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-timeline {
  display: none;
}

.topline {
  text-transform: uppercase;
  letter-spacing: .1em;
  border-top: 1px solid;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 10px;
  font-weight: 600;
}

.topline-item {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow-x: hidden;
}

.section {
  padding-top: calc(var(--g) * 1.5);
  padding-bottom: calc(var(--g) * 2.65);
}

.section > .topline:first-child, .section > .container:first-child > .topline:first-child {
  margin-top: calc(calc(var(--g) * 1.5) * -1);
  margin-bottom: calc(var(--g) * 1.5);
}

.section[class*="theme-"], .section[class*="theme-"] + .section:not([class*="theme-"]) {
  padding-top: calc(var(--g) * 2.65);
}

.section[class*="theme-"] > .topline:first-child, .section[class*="theme-"] > .container:first-child > .topline:first-child, .section[class*="theme-"] + .section:not([class*="theme-"]) > .topline:first-child, .section[class*="theme-"] + .section:not([class*="theme-"]) > .container:first-child > .topline:first-child {
  margin-top: 0;
}

.section:not([class*="theme-"]) + .section:not([class*="theme-"]), .section.theme-white + .section.theme-white, .section.theme-light + .section.theme-light, .section.theme-dark + .section.theme-dark, .section.theme-dim + .section.theme-dim, .section.theme-global + .section.theme-global, .section.theme-mindcurv + .section.theme-mindcurv, .section.theme-initions + .section.theme-initions, .section.theme-wysiwyg + .section.theme-wysiwyg, .section.theme-eccelerate + .section.theme-eccelerate, .section.theme-ccg + .section.theme-ccg, .section.theme-igniti + .section.theme-igniti, .section.theme-ccx + .section.theme-ccx {
  padding-top: 0;
}

.section.c-page-intro {
  padding-top: calc(calc(var(--g) * 1.5) * 1.5);
  padding-bottom: calc(calc(var(--g) * 2.65) * 1.25);
}

.teaser {
  flex-direction: column;
  display: flex;
}

.teaser-link {
  display: inherit;
  flex-direction: inherit;
  height: 100%;
  position: relative;
}

.teaser-link a:not(.stretched-link), .teaser-link button:not(.stretched-link) {
  z-index: 2;
  position: relative;
}

.teaser-link .teaser-image {
  transition: filter .2s ease-in-out;
}

.teaser-link:hover .teaser-image {
  filter: brightness(.8) grayscale();
}

.teaser-link:hover .teaser-image-overlay {
  text-decoration: underline;
}

.teaser-link:hover:not(:has(.stretched-link:hover)) .teaser-image {
  filter: none;
}

.teaser-link:hover:not(:has(.stretched-link:hover)) .teaser-image-overlay {
  text-decoration: none;
}

.teaser-image {
  max-width: 100%;
  height: auto;
}

.teaser-image[width], .teaser-image[height] {
  object-fit: cover;
}

.top-partners .teaser-image {
  object-fit: contain;
  object-position: 0 50%;
  min-width: 70%;
  max-width: 200px;
  min-height: 70px;
  max-height: 80px;
}

.teaser-image-overlay {
  padding: calc(var(--g) / 2);
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.teaser-action {
  margin-top: auto;
}

.job-search[data-results] {
  position: relative;
}

.job-search[data-results]:after {
  padding-top: .5rem;
  font-size: .75em;
  display: block;
  position: absolute;
  top: 100%;
}

html[lang="en"] .job-search[data-results]:after {
  content: attr(data-results) " results";
}

html[lang="de-DE"] .job-search[data-results]:after {
  content: attr(data-results) " Ergebnisse";
}

html[lang="en"] .job-search[data-results="1"]:after {
  content: attr(data-results) " result";
}

html[lang="de-DE"] .job-search[data-results="1"]:after {
  content: attr(data-results) " Ergebnis";
}

.job-content ul, .job-content dl {
  margin: 1em 0;
  line-height: 1.7;
  list-style-type: square;
}

.job-content ul li ul, .job-content dl li ul {
  margin-top: 0;
}

.job-content ul:last-child, .job-content dl:last-child {
  margin-bottom: 0;
}

.job-content p + ul {
  margin-top: -.5em;
}

.quote figure {
  --mc-gutter-x: var(--g);
  --mc-gutter-y: 0;
  margin: 0;
  margin-top: calc(-1 * var(--mc-gutter-y));
  margin-right: calc(-.5 * var(--mc-gutter-x));
  margin-left: calc(-.5 * var(--mc-gutter-x));
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.quote figure > * {
  padding-right: calc(var(--mc-gutter-x) * .5);
  padding-left: calc(var(--mc-gutter-x) * .5);
  margin-top: var(--mc-gutter-y);
  flex: none;
  width: 100%;
  max-width: 100%;
}

@media (width >= 768px) {
  .quote figure > * {
    flex: none;
    width: 83.3333%;
  }
}

@media (width >= 992px) {
  .quote figure > * {
    flex: none;
    width: 66.6667%;
  }
}

.quote blockquote {
  margin: 0;
  font-weight: 700;
}

.quote blockquote a {
  font-weight: inherit;
}

.quote blockquote.quote-size-medium {
  font-size: 24px;
  line-height: 28px;
}

@media (width >= 768px) {
  .quote blockquote.quote-size-medium {
    font-size: 44px;
    line-height: 48px;
  }
}

@media (width >= 992px) {
  .quote blockquote.quote-size-medium {
    font-size: 52px;
    line-height: 58px;
  }
}

.quote blockquote.quote-size-large {
  letter-spacing: .2px;
  font-size: 28px;
  line-height: 34px;
}

@media (width >= 768px) {
  .quote blockquote.quote-size-large {
    letter-spacing: .2px;
    font-size: 56px;
    line-height: 64px;
  }
}

@media (width >= 992px) {
  .quote blockquote.quote-size-large {
    letter-spacing: .4px;
    font-size: 72px;
    line-height: 80px;
  }
}

.quote blockquote.quote-size-xlarge {
  letter-spacing: .3px;
  font-size: 38px;
  line-height: 40px;
}

@media (width >= 768px) {
  .quote blockquote.quote-size-xlarge {
    letter-spacing: .4px;
    font-size: 76px;
    line-height: 80px;
  }
}

@media (width >= 992px) {
  .quote blockquote.quote-size-xlarge {
    letter-spacing: .5px;
    font-size: 96px;
    line-height: 100px;
  }
}

.quote cite {
  font-style: normal;
}

.quote .quoted {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 600;
}

.quote .lavalamp [data-lavalamp] {
  width: 100%;
  margin: 0%;
}

.zipfi {
  justify-content: center;
  min-height: auto;
  position: relative;
}

.zipfi:before {
  content: " ";
  z-index: 2;
  width: var(--g);
  border-bottom: var(--g) solid transparent;
  border-left: var(--g) solid white;
  background: none;
  height: 0;
  position: absolute;
  top: 0;
  left: 50%;
}

.zipfi .portrait {
  background-color: #0000;
}

.zipfi .portrait img {
  height: calc(100vw - var(--g) * 2);
  object-fit: cover;
  vertical-align: bottom;
  border-radius: 50%;
  width: 100%;
}

@media (width >= 576px) {
  .zipfi .portrait img {
    height: calc((100vw - var(--g) * 4) / 3);
  }
}

@media (width >= 84em) {
  .zipfi .portrait img {
    height: calc((100vw - var(--g) * 6) / 3);
  }
}

@media (width >= 100em) {
  .zipfi .portrait img {
    height: calc((100vw - var(--g) * 8) / 3);
  }
}

.megaheadline {
  white-space: nowrap;
  overflow: hidden;
}

.megaheadline h2, .megaheadline .h2 {
  color: inherit;
  font-size: 27vw;
  font-weight: 600;
  line-height: 1.2;
}

.megaheadline h2 span, .megaheadline .h2 span {
  margin-right: -.12em;
}

.megalinks {
  margin-left: -1.55vw;
}

.megalinks-link {
  letter-spacing: -.05em;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1rem;
  font-size: 25vw;
  font-weight: 300;
  line-height: .95;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.megalinks-link:hover {
  color: var(--mc-global);
}

@media (width >= 576px) {
  .megalinks-link {
    font-size: 15.5vw;
  }
}

.std-contact {
  padding-bottom: 0;
  overflow: hidden;
  padding-top: 0 !important;
}

.std-contact:not(.theme-dark) {
  background: linear-gradient(90deg, #dfdbda, #d0c4c3);
}

.std-contact .contact-person-info {
  z-index: 1;
  position: relative;
}

@media (width <= 575.98px) {
  .std-contact .contact-person-info p {
    font-size: 13px;
  }
}

.std-contact .contact-person-image {
  width: 60vw;
  height: auto;
}

@media (width >= 576px) {
  .std-contact .contact-person-image {
    width: 40vw;
  }
}

@media (width >= 992px) {
  .std-contact .contact-person-image {
    max-width: 100%;
  }
}

.contact-person-name {
  font-weight: 700;
  display: block;
}

.contact-person-image-container .contact-person-image {
  filter: drop-shadow(0 2px 60px #1f1f1e26);
}

.std-contact.theme-dark .contact-person-image-container .contact-person-image {
  filter: none;
}

.mcg-contact {
  background: linear-gradient(90deg, #dfdbda, #d0c4c3);
  padding-bottom: 0;
  overflow: hidden;
  padding-top: calc(var(--g) * 1.25) !important;
}

.mcg-contact .contact-person-info {
  color: #000;
}

.mcg-contact .contact-person-cta {
  text-align: right;
}

.mcg-contact-double-item {
  overflow: hidden;
}

@media (width >= 768px) {
  .mcg-contact + .mcg-contact {
    border-top: 1px solid #000;
  }
}

@media (width <= 767.98px) {
  .mcg-contact-double-item:first-of-type .row {
    border-bottom: 1px solid #000;
  }

  .mcg-contact-double-item:last-of-type .row {
    padding-top: 2em;
  }
}

@media (width >= 768px) {
  .mcg-contact-double-item:first-of-type .row {
    padding-right: 1em;
  }

  .mcg-contact-double-item:last-of-type .row {
    padding-left: 1em;
  }
}

@media (width >= 992px) {
  .mcg-contact-double-item:first-of-type .row {
    padding-right: 2em;
  }

  .mcg-contact-double-item:last-of-type .row {
    padding-left: 2em;
  }
}

.contact-person-image-container {
  max-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1em;
  position: relative;
}

.contact-person-image-container .mcg-ring {
  z-index: 0;
  max-width: 77%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.mcg-contact .contact-person-image-container .contact-person-image {
  z-index: 1;
  position: relative;
}

@media (width >= 576px) {
  .contact-person-container-wide .contact-person-image-container {
    margin-top: -50px;
  }
}

@media (width >= 768px) {
  .contact-person-container-wide .contact-person-image-container {
    margin-top: -100px;
  }

  .contact-person-image-container {
    max-width: 32.5vw;
  }

  .contact-person-container-wide .contact-person-info p {
    max-width: 75%;
  }
}

@media (width >= 992px) {
  .contact-person-container-wide .contact-person-image-container {
    margin-top: -120px;
  }

  .contact-person-container-wide .contact-person-info {
    padding-left: calc(var(--g) * 2);
  }

  .contact-person-container-wide .contact-person-cta {
    padding-right: calc(var(--g) * 2);
  }
}

.c-image-header .c-image-header--headline-container {
  padding-bottom: calc(var(--g) * 3);
  padding-top: calc(var(--g) * 3);
}

@media (width <= 768px) {
  .c-image-header .c-image-header--headline-container {
    padding-bottom: calc(var(--g) * 4);
    padding-top: calc(var(--g) * 4);
  }
}

.c-image-header .c-image-header--headline-container .c-image-header--text {
  letter-spacing: .05em;
  filter: drop-shadow(0 2px 60px #1f1f1e26);
}

.c-image-header .c-image-header--headline-container .c-image-header--text h1, .c-image-header .c-image-header--headline-container .c-image-header--text .h1, .c-image-header .c-image-header--headline-container .c-image-header--text h2, .c-image-header .c-image-header--headline-container .c-image-header--text .h2, .c-image-header .c-image-header--headline-container .c-image-header--text h3, .c-image-header .c-image-header--headline-container .c-image-header--text .h3, .c-image-header .c-image-header--headline-container .c-image-header--text h4, .c-image-header .c-image-header--headline-container .c-image-header--text .h4, .c-image-header .c-image-header--headline-container .c-image-header--text h5, .c-image-header .c-image-header--headline-container .c-image-header--text .h5, .c-image-header .c-image-header--headline-container .c-image-header--text p {
  font-size: inherit;
  margin-bottom: 0;
}

.case-slide {
  border-radius: 24px;
  width: 100%;
  display: grid;
  position: relative;
}

.case-slide-bg {
  border-radius: inherit;
  grid-area: 1 / 1;
  display: block;
  overflow: hidden;
}

.case-slide-body {
  color: var(--case-color, inherit);
  flex-direction: column;
  grid-area: 1 / 1;
  display: flex;
}

@media (width >= 768px) {
  .case-slide-body {
    width: 52.5%;
  }
}

@media (width <= 575.98px) {
  .case-slide-body p {
    font-size: .875rem;
  }
}

.case-slide-body .btn {
  --mc-btn-color: var(--case-btn-color);
  --mc-btn-bg: var(--case-btn-bg);
  --mc-btn-hover-color: var(--case-btn-hover-color);
  --mc-btn-hover-bg: var(--case-btn-hover-bg);
  width: max-content;
}

.case-slide-eyebrow {
  color: var(--case-eyebrow);
  margin-bottom: 0;
}

.lead {
  line-height: 1.7;
}

.quote {
  text-align: center;
}

.quote blockquote {
  font-family: Sentinel, serif;
  font-weight: 200;
}

.mindfeed-content h2, .mindfeed-content .h2 {
  margin-bottom: calc(var(--g) * .5);
}

.mindfeed-content p + h3, .mindfeed-content p + .h3 {
  margin-top: var(--g);
}

.mindfeed-content ul:not([class]) {
  margin-bottom: calc(var(--g) * .5);
}

.mindfeed-content ul:not([class]) > li {
  margin-bottom: calc(var(--g) * .25);
}

.lavalamp {
  mix-blend-mode: darken;
  max-width: 75vw;
  margin: 0 auto;
}

.lavalamp [data-lavalamp] {
  aspect-ratio: 1;
  width: 150%;
  margin: -25%;
}

.flickity-page-dots {
  bottom: calc(var(--g) * -1) !important;
}

.flickity-page-dot:focus {
  box-shadow: none !important;
}

.flickity-page-dot:focus-visible {
  box-shadow: 0 0 0 5px #19f !important;
}

.container > .flickity-full {
  width: calc(100 * var(--vws, 1vw));
  margin-left: calc(calc((100 * var(--vws, 1vw)  - (90 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
  margin-right: calc(calc((100 * var(--vws, 1vw)  - (90 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
}

.container > .flickity-full .flickity-cell, .container > .flickity-full:not(.flickity-enabled) > * {
  width: 82.5%;
}

@media (width >= 576px) {
  .container > .flickity-full {
    width: calc(100 * var(--vws, 1vw));
    margin-left: calc(calc((100 * var(--vws, 1vw)  - (94 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
    margin-right: calc(calc((100 * var(--vws, 1vw)  - (94 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
  }

  .container > .flickity-full .flickity-cell, .container > .flickity-full:not(.flickity-enabled) > * {
    width: 86.1667%;
  }
}

@media (width >= 992px) {
  .container > .flickity-full {
    width: calc(100 * var(--vws, 1vw));
    margin-left: calc(calc((100 * var(--vws, 1vw)  - (96 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
    margin-right: calc(calc((100 * var(--vws, 1vw)  - (96 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
  }

  .container > .flickity-full .flickity-cell, .container > .flickity-full:not(.flickity-enabled) > * {
    width: 88%;
  }
}

@media (width >= 1344px) {
  .container > .flickity-full {
    width: calc(100 * var(--vws, 1vw));
    margin-left: calc(calc((100 * var(--vws, 1vw)  - (88 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
    margin-right: calc(calc((100 * var(--vws, 1vw)  - (88 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
  }

  .container > .flickity-full .flickity-cell, .container > .flickity-full:not(.flickity-enabled) > * {
    width: 80.6667%;
  }
}

@media (width >= 1600px) {
  .container > .flickity-full {
    width: calc(100 * var(--vws, 1vw));
    margin-left: calc(calc((100 * var(--vws, 1vw)  - (80 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
    margin-right: calc(calc((100 * var(--vws, 1vw)  - (80 * var(--vws, 1vw))) * -.5)  - calc(var(--g) * .5));
  }

  .container > .flickity-full .flickity-cell, .container > .flickity-full:not(.flickity-enabled) > * {
    width: 73.3333%;
  }
}

.container > .flickity-full:not(.flickity-enabled) > * {
  margin-left: auto !important;
  margin-right: auto !important;
}

div[data-service] .cll {
  --im-font-family: AvenirNext, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --im-color: #fff;
  --im-bg: black;
  --im-link-color: currentColor;
  --im-link-hover-color: currentColor;
  --im-btn-color: white;
  --im-btn-bg: transparent;
  --im-btn-hover-bg: transparent;
  --im-btn-active-box-shadow: none;
  --im-btn-s-color: white;
  --im-btn-s-bg: transparent;
  --im-btn-s-hover-bg: transparent;
  --im-btn-s-active-bg: transparent;
  --im-overlay-bg: transparent;
  font-family: var(--im-font-family);
  font-weight: 300;
}

div[data-service] .cll .c-l-b, div[data-service] .cll .c-la-b {
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  transition: none;
}

div[data-service] .cll .c-l-b:hover, div[data-service] .cll .c-l-b:active, div[data-service] .cll .c-la-b:hover, div[data-service] .cll .c-la-b:active {
  text-decoration: underline;
}

div[data-service] .cll .c-n-t a {
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
}

div[data-service] .cll .c-n-t a:hover, div[data-service] .cll .c-n-t a:active {
  border-bottom-color: #0000;
}

.nav-label {
  padding: .5rem 1rem;
  display: block;
}

#join-svg, #jobs-svg {
  z-index: -1;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
}

[data-before-animation="true"] * {
  opacity: 0;
}

@media (width >= 768px) {
  .container-indented {
    max-width: 78.33%;
  }
}

@media (width >= 992px) {
  .container-indented {
    max-width: 80%;
  }
}

@media (width >= 1344px) {
  .container-indented {
    max-width: 73.33%;
  }
}

@media (width >= 1600px) {
  .container-indented {
    max-width: 66.66%;
  }
}

@media (width >= 768px) {
  .container-double-indented {
    max-width: 78.33%;
  }
}

@media (width >= 992px) {
  .container-double-indented {
    max-width: 64%;
  }
}

@media (width >= 1344px) {
  .container-double-indented {
    max-width: 58.665%;
  }
}

@media (width >= 1600px) {
  .container-double-indented {
    max-width: 53.3325%;
  }
}

/*# sourceMappingURL=main.css.map */
