.job-search[data-results] {
	position: relative;

	&::after {
		display: block;
		position: absolute;
		top: 100%;
		padding-top: $form-label-margin-bottom;
		font-size: $form-label-font-size;

		html[lang="en"] & {
			content: attr(data-results) " results";
		}

		html[lang="de-DE"] & {
			content: attr(data-results) " Ergebnisse";
		}
	}
}

.job-search[data-results="1"]::after {
	html[lang="en"] & {
		content: attr(data-results) " result";
	}

	html[lang="de-DE"] & {
		content: attr(data-results) " Ergebnis";
	}
}


.job-content {

	ul, dl {
		//@extend .list;
		line-height: 1.7;
		list-style-type: square;
		margin: 1em 0;

		li ul {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	p + ul {
		margin-top: -0.5em;
	}
}
