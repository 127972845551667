// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 1.5. Include custom functions as well
@import "functions";

// 2. Include any default variable overrides here
@import "variables";

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 3.1. Extend utilities
$utilities: extend-utilities($utilities, "font-weight", (
	"semibold": 600
));

// 3.5. Include custom root styles
@import "root";

// 4. Include any optional Bootstrap CSS as needed
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/helpers/clearfix";
@import "~bootstrap/scss/helpers/stretched-link";
// @import "~bootstrap/scss/...";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// 6. Add additional custom code here
@import "view-transitions";
@import "fonts";
@import "buttons";       // Customized Bootstrap buttons
@import "forms";         // Modified Bootstrap forms
@import "theme";
@import "page";
@import "navbar";        // Extended Bootstrap navbar
@import "list";
@import "video";
@import "topline";
@import "section";
@import "teaser";
@import "jobs";
@import "quotes";
@import "megaheadline";
@import "megalinks";
@import "contact-teasers";
@import "c-image-header";
@import "case-slide";
@import "type";
@import "mindfeed";
@import "lavalamp";

@import "flickity";
@import "iframemanager";

// Nav label has the the same padding as `.nav-link` for non-interactive elements
.nav-label {
	padding: $nav-link-padding-y $nav-link-padding-x;
	display: block;
}

#join-svg,
#jobs-svg {
	position: absolute;
	top: 0;
	z-index: -1;
	width: 100%;
	height: auto;
	// contain: layout paint;
}

/**
 * Hide contents of elements with the `before-animation` data attribute
 *
 * This attribute gets removed when the animation starts making sure there is
 * no flash of preanimated content
 */
[data-before-animation="true"] * {
	opacity: 0;
}

/**
 * additional container width
 */

.container-indented {
	@include media-breakpoint-up(md) {
		max-width: 78.33%;
	}
	@include media-breakpoint-up(lg) {
		max-width: 80%;
	}
	@include media-breakpoint-up(xl) {
		max-width: 73.33%;
	}
	@include media-breakpoint-up(xxl) {
		max-width: 66.66%;
	}
}

.container-double-indented {
	@include media-breakpoint-up(md) {
		max-width: 78.33%;
	}
	@include media-breakpoint-up(lg) {
		max-width: 64%;
	}
	@include media-breakpoint-up(xl) {
		max-width: 58.665%;
	}
	@include media-breakpoint-up(xxl) {
		max-width: 53.3325%;
	}
}
