$section-padding-top: #{calc(var(--g) * 1.5)};
$section-padding-bottom: #{calc(var(--g) * 2.65)}; // was 3.65

.section {
	padding-top: $section-padding-top;
	padding-bottom: $section-padding-bottom;

	// First-child `.topline` gets pulled up by section top padding
	> .topline:first-child,
	> .container:first-child > .topline:first-child {
		margin-top: calc(#{$section-padding-top} * -1);
		margin-bottom: $section-padding-top;
	}

	// Sections with a theme and sections with no theme following sections with a
	// theme have a larger top padding
	&[class*="theme-"],
	&[class*="theme-"] + &:not([class*="theme-"]) {
		padding-top: $section-padding-bottom;

		// Do not pull up first-child `.topline` in these sections
		> .topline:first-child,
		> .container:first-child > .topline:first-child {
			margin-top: 0;
		}
	}

	// Sections with no theme following each other have no top padding
	&:not([class*="theme-"]) + &:not([class*="theme-"]) {
		padding-top: 0;
	}

	// Sections with the same theme following each other have no top padding
	@each $name, $theme in $theme-colors {
		&.theme-#{$name} + &.theme-#{$name} {
			padding-top: 0;
		}
	}

	// Intro Section with slightly more padding
	&.c-page-intro {
		padding-top: calc(#{$section-padding-top} * 1.5);
		padding-bottom: calc(#{$section-padding-bottom} * 1.25);
	}
}
