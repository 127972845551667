@import "~bootstrap/scss/forms";

.form-label {
	text-transform: uppercase;
	letter-spacing: .15em;
}

.form-control[type="file"] {
	line-height: $input-line-height * 1.6;
}

.invalid-feedback {
	display: inline-block !important;
}



/* new form styles for testing purposes */

.mcg-form {
	font-weight: 500;

	.form-label {
		font-size: 10px;
		font-weight: 600;
		letter-spacing: .1em;
	}

	.form-check-label {
		font-size: 16px;
		font-weight: 400;
	}

	.form-control {
		padding: .4166em 1.25em;
	}

	.form-control,
	.form-select {
		background-color: rgba(0,0,0, 0.03);
		border-radius: 8px;
		color: inherit;
		opacity: 1;
	}

	.form-control:focus,
	.form-select:focus {
		background-color: rgba(0,0,0, 0.06);
		box-shadow: none;
	}

	.form-select option {
		color: #222;
	}

	.form-select {
		line-height: 0.917;
	}

	.form-control::file-selector-button {
		color: inherit;
	}

	.form-check {
		line-height: 1em;
	}

	.form-check-input {
		color: inherit;
		border-radius: 2px;
		opacity: 1;
		background-color: rgba(0,0,0, 0.06);
		border-width: 0;

		width: 1em;
		height: 1em;
	}

	.form-check-input:checked[type="checkbox"] {
		background-color: rgba(0,0,0, 0.12);
	}

	.form-check-input:focus[type="checkbox"] {
		box-shadow: none;
	}

	.form-control::file-selector-button {
		background-color: rgba(0,0,0, 0.06);
	}

	.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
		background-color: rgba(0,0,0, 0.12);
	}

	textarea.form-control {
		line-height: 1.21;
	}

	.mandatory-label {
		display: inline;
		writing-mode: vertical-lr;
		transform: rotate(180deg);
		text-orientation: mixed;
		position: absolute;
		top: 33px;
		right: -35px;
		width: auto;
	}

	&.section .row {
		position: relative;
	}

	&.theme-igniti,
	&.theme-dark,
	&.theme-ccg,
	&.theme-wysiwyg,
	&.theme-ccx {

		.form-control,
		.form-select {
			background-color: rgba(255,255,255, 0.12);
		}

		.form-select {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
		}

		.form-check-input {
			background-color: rgba(255,255,255, 0.24);
		}

		.form-check-input:checked[type="checkbox"] {
			background-color: rgba(255,255,255, 0.36);
		}

		.form-control:focus,
		.form-select:focus {
			background-color: rgba(255,255,255, 0.24);
		}

		.form-control::file-selector-button {
			background-color: rgba(255,255,255, 0.24);
		}

		.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
			background-color: rgba(255,255,255, 0.36);
		}
	}
}
