/**
 * Component Page Intro
 */

.lead {
	line-height: $line-height-lg;
}

.quote {
	text-align: center;

	blockquote {
		font-family: $font-family-serif;
		font-weight: 200;
	}
}
