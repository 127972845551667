@import "/node_modules/@orestbida/iframemanager/dist/iframemanager.css";

div[data-service] .cll {
	--im-font-family: #{$font-family-sans-serif};
	--im-color: #fff;
	--im-bg: black;
	--im-link-color: currentColor;
	--im-link-hover-color: currentColor;

	--im-btn-color: white;
	--im-btn-bg: transparent;
	--im-btn-hover-bg: transparent;
	--im-btn-active-box-shadow: none;

	--im-btn-s-color: white;
	--im-btn-s-bg: transparent;
	--im-btn-s-hover-bg: transparent;
	--im-btn-s-active-bg: transparent;

	--im-overlay-bg: transparent;

	font-family: var(--im-font-family);
	font-weight: $font-weight-base;

	// buttons
	.c-l-b,
	.c-la-b {
		font-size: 14px;
		line-height: 15px;
		font-weight: 500;
		transition: none;
		box-shadow: none;

		&:hover,
		&:active {
			text-decoration: underline;
		}
	}

	// text link
	.c-n-t a {
		font-size: 14px;
		line-height: 15px;
		font-weight: 500;

		&:hover,
		&:active {
			border-bottom-color: transparent;
		}
	}
}

