.topline {
	border-top: 1px solid;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	padding-top: 5px;
	padding-bottom: 5px;
}

.topline-item {
	display: inline-block;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}
