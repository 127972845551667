.flickity-page-dots {
	bottom: calc(var(--g) * -1) !important;
}

.flickity-page-dot {

	&:focus {
		box-shadow: none !important;
	}

	&:focus-visible {
		box-shadow: 0 0 0 5px #19F !important;
	}
}

.container {

	> .flickity-full {
		@each $breakpoint, $_ in $grid-breakpoints {
			@if map-has-key($container-max-widths, $breakpoint) {
				@include media-breakpoint-up($breakpoint) {
					$container-width: map-get($container-max-widths, $breakpoint);
					$margin-value: calc(((100 * var(--vws, 1vw)) - (#{strip-unit($container-width)} * var(--vws, 1vw))) * -0.5);

					width: calc(100 * var(--vws, 1vw));
					margin-left: calc(#{$margin-value} - #{$container-padding-x});
					margin-right: calc(#{$margin-value} - #{$container-padding-x});

					.flickity-cell,
					&:not(.flickity-enabled) > * {
						width: calc($container-width / $grid-columns * 11);
					}
				}
			}
		}

		// Fallback for when Flickity is not enabled
		&:not(.flickity-enabled) > * {
			margin-left: auto !important;
			margin-right: auto !important;
		}
	}
}
