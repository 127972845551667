.case-slide {
	position: relative;
	display: grid;
	width: 100%;
	border-radius: $border-radius-lg;
}

.case-slide-bg {
	display: block;
	border-radius: inherit;
	overflow: hidden;
	grid-area: 1 / 1;
}

.case-slide-body {
	color: var(--case-color, inherit);
	grid-area: 1 / 1;
	display: flex;
	flex-direction: column;

	@include media-breakpoint-up(md) {
		width: 52.5%;
	}

	@include media-breakpoint-down(sm) {
		p {
			font-size: $font-size-sm;
		}
	}


	.btn {
		--mc-btn-color: var(--case-btn-color);
		--mc-btn-bg: var(--case-btn-bg);
		--mc-btn-hover-color: var(--case-btn-hover-color);
		--mc-btn-hover-bg: var(--case-btn-hover-bg);
		width: max-content;
	}
}

.case-slide-eyebrow {
	color: var(--case-eyebrow);
	margin-bottom: 0;
}
