@import "~bootstrap/scss/navbar";

// Always expanded navbar
.navbar-nav-expanded {
	flex-direction: row;

	.dropdown-menu {
		position: absolute;
	}

	.nav-link {
		padding-right: $navbar-nav-link-padding-x;
		padding-left: $navbar-nav-link-padding-x;
	}
}

.navbar-brand {
	width: 20%;
	min-width: 160px;
	//max-width: 210px;

	img {
		max-width: 100%;
		height: auto;
	}

	&,
	&:hover {
		color: inherit;
	}
}

.navbar-nav {
	margin-top: calc(var(--g)*-.4);

	@include media-breakpoint-up(lg) {
		margin-top: calc(var(--g)*-.35);
	}

	/*@include media-breakpoint-up(xl) {
		margin-top: calc(var(--g)*-.3);
	}*/
}

.navbar-toggler {
	// position: fixed;
	z-index: $zindex-offcanvas;
	bottom: $navbar-padding-y;
	color: #aaa;
	background-color: var(--mc-menu-bg, #{$black});

	@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
		background-color: transparent;
		-webkit-backdrop-filter: invert(1) grayscale(1) contrast(20) blur(10px) contrast(30);
		backdrop-filter: invert(1) grayscale(1) contrast(20) blur(10px) contrast(30);
	}

	@include media-breakpoint-up(md) {
		bottom: auto;
	}

	&.active {
		color: var(--mc-color);
		background-color: var(--mc-bg);
		z-index: $zindex-offcanvas + 1;
		backdrop-filter: none;
	}
}

.navbar-toggler-icon {
	position: relative;

	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 10%;
		height: 2px;
		width: 80%;
		background-color: currentColor;
		transition: transform .15s ease-out;
	}

	&::before {
		top: 25%;
	}

	&::after {
		bottom: 25%;
	}

	.navbar-toggler.active & {

		&::before {
			transform: translateY(0.35em) rotate(135deg);
		}

		&::after {
			transform: translateY(-0.3em) rotate(-135deg);
		}
	}
}

.navbar-link-underline {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 1px;
	height: 2px;
	background-color: currentColor;
	transition: transform 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
	transform: translateX(calc(var(--x, 0) * 1px)) scaleX(var(--w, 0));
	transform-origin: left;
}
