/**
 * striped lists
 * eg for presentation of key figures
 */

.list {
	list-style: none;
	padding: 0;
	border-top: 1px solid;

	> li,
	> dt,
	> dd,
	> .list-item {
		border-bottom: 1px solid;
		padding: calc(var(--g) / 2) 0;
	}
}

dl.list {
	display: grid;
	grid-template-columns: auto auto;

	> dd,
	> dt,
	> .list-item {
		width: 100%;
	}

	> dt {
		font-weight: inherit;
	}

	> dd {
		font-weight: 600;
		text-align: right;
		margin-bottom: 0;
		margin-left: auto;
		align-self: end;
		padding-left: calc(var(--g) / 4);
	}
}
