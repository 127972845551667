// Empty `$theme-colors` before importing Bootstrap buttons so no variants are generated
$_theme-colors: $theme-colors;
$theme-colors: ();

@import "~bootstrap/scss/buttons";

$theme-colors: (
	"gray": $gray-250 $gray-800,
);

.btn {
	color: var(--mc-btn-color);
	background-color: var(--mc-btn-bg);
	border-color: var(--mc-btn-bg);
	letter-spacing: 0.025em;
	text-transform: uppercase;

	&:hover {
		color: var(--mc-btn-hover-color);
		background-color: var(--mc-btn-hover-bg);
		border-color: var(--mc-btn-hover-bg);
	}
}

@each $theme, $values in $theme-colors {
	$background: nth($values, 1);
	$color: nth($values, 2);

  .btn-#{$theme} {
    @include button-variant($background, $background, $color);
  }
}

.btn-outline {
	background-color: transparent;
	border-color: $black;
	color: $black;
}

.btn-outline.halo-wysiwyg:hover {
	background-color: $wysiwyg;
	border-color: $wysiwyg;
	color: white;
}

.btn-outline.halo-igniti:hover {
	background-color: $igniti;
	border-color: $igniti;
	color: white;
}

.btn-outline.halo-ccg:hover {
	background-color: $ccg;
	border-color: $ccg;
	color: white;
}

.btn-outline.halo-eccelerate:hover {
	background-color: $eccelerate;
	border-color: $eccelerate;
	color: white;
}

.btn-outline.halo-mindcurv:hover {
	background-color: $global;
	border-color: $global;
	color: black;
}

.btn-outline.halo-initions:hover {
	background-color: $initions;
	border-color: $initions;
	color: white;
}


// Reset `$theme-colors`
$theme-colors: $_theme-colors;
