.page {
	--mc-body-font-size: clamp(#{$font-size-base}, 1.25vw, 24px);

	display: flex;
	flex-direction: column;
	min-height: 100vh;
}


/* =============================================================================
 * Page header
 * ========================================================================== */

 .page-header {
	font-size: max(#{$font-size-base}, 1.5vw);
	font-weight: 500;

	// Add z-index if the header is positioned absolutely
	&.position-absolute {
		z-index: 1;
	}

	.nav-link {
		color: inherit;
	}
}


/* =============================================================================
 * Page menu
 * ========================================================================== */

$page-menu-breakpoint: md;

.page-menu {

	&.offcanvas {
		width: 100%;
		border-left: none;
		top: 0;
		right: 0;
		transform: translateY(100%);

		@include media-breakpoint-up($page-menu-breakpoint) {
			width: 50%;
			transform: translateX(100%);
		}

		&.show {
			transform: none;
		}
	}

	.offcanvas-body {
		display: flex;
		padding: map-get($spacers, 1);
		flex-direction: column-reverse;

		@include media-breakpoint-up($page-menu-breakpoint) {
			flex-direction: column;
		}
	}

	.nav {
		flex-direction: inherit;
	}

	.nav-link {

		&:hover {
			text-decoration: underline;
			text-underline-offset: 0.1em;
		}

		&.active {
			color: var(--mc-bg);

			&:hover {
				text-decoration-color: var(--mc-menu-color, #{$offcanvas-color});
			}
		}
	}
}

.page-menu-primary {
	margin-bottom: auto;

	@include media-breakpoint-up($page-menu-breakpoint) {
		margin-top: auto;
	}

	.nav-item {
		margin-bottom: map-get($spacers, 1\/2);
	}
}

.page-menu-secondary {
	margin-top: auto;
	margin-bottom: map-get($spacers, 2);

	@include media-breakpoint-up($page-menu-breakpoint) {
		margin-top: 0;
		margin-bottom: 0;
	}
}


/* =============================================================================
 * Page main
 * ========================================================================== */

 .page-main {
	flex-grow: 1;

	a:not([class]) {
		font-weight: 500;
		text-underline-offset: .25em;

		&:hover {
			text-decoration: underline dashed;
		}
	}
}


/* =============================================================================
 * Page footer
 * ========================================================================== */

 .page-footer {
	font-size: 14px;
	line-height: 2;

	a {
		color: inherit;
	}

	.nav {
		// Negate `.nav-link` padding so links align with other elements
		margin-left: $nav-link-padding-x * -1;
	}

	.nav-label,
	.nav-link {
		color: $gray-700;
	}

	.nav-link {

		&:hover {
			color: var(--mc-color);
		}

		&.active {
			font-weight: 600;
		}
	}
}
