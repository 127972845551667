.teaser {
	display: flex;
	flex-direction: column;
}

.teaser-link {
	height: 100%;
	position: relative;
	display: inherit;
	flex-direction: inherit;

	// Elevate interactive elements so they are still clickable
	a, button {
		&:not(.stretched-link) {
			position: relative;
			z-index: 2;
		}
	}

	.teaser-image {
		transition: list-replace($transition-base, all, filter);
	}

	&:hover {
		.teaser-image {
			filter: brightness(0.8) grayscale(1);
		}

		.teaser-image-overlay {
			text-decoration: underline;
		}

		&:not(:has(.stretched-link:hover)) {
			.teaser-image {
				filter: none;
			}

			.teaser-image-overlay {
				text-decoration: none;
			}
		}
	}
}

.teaser-image {
	max-width: 100%;
	height: auto;

	&[width],
	&[height] {
		object-fit: cover;
	}
}

.top-partners {

	.teaser-image {
		object-fit: contain;
		object-position: 0 50%;
		min-height: 70px;
		min-width: 70%;
		max-height: 80px;
		max-width: 200px;
	}
}

.teaser-image-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: calc(var(--g) / 2);
	color: white;
}

.teaser-action {
	margin-top: auto;
}
