/**
 *
 * used in RevolutioniseTeaser
 *
 */

.megaheadline {
	overflow: hidden;
	white-space: nowrap;

	h2 {
		font-size: 27vw;
		line-height: 1.2;
		font-weight: 600;
		color: inherit;

		span {
			margin-right: -0.12em;
		}
	}
}
