// Color system

$white:    #fff;
$gray-100: #f9f9f9;
$gray-200: #f6f6f6;
$gray-250: #ECECEB;
$gray-300: #e0e0e0;
$gray-400: #d9d8d4;
$gray-500: #d4cfcc;
$gray-600: #a09d93;
$gray-700: #888;
$gray-800: #5b5b5d;
$gray-900: #222;
$black:    #000;

$global:     #fad200; // commerce
$wysiwyg:    #5000DC; // brand
$igniti:     #0F9BBE; // ddevice
$initions:   #FF6600; // data
$eccelerate: #00C6F7; // strategy
$ccg:        #A4E800; // salesforce
$ccx:        #FF005C; // cloud

$theme-colors: (
	"white":      $white      $gray-900,
	"light":      $gray-100   $gray-900,
	"dark":       $black      $gray-100,
	"dim":        $gray-200   $gray-900,
	"global":     $global     $gray-900,
	"mindcurv":   $global     $gray-900,
	"initions":   $initions   $white,
	"wysiwyg":    $wysiwyg    $white,
	"eccelerate": $eccelerate $white,
	"ccg":        $ccg        $gray-900,
	"igniti":     $igniti     $white,
	"ccx":        $ccx        $white,
);

$theme-colors-rgb: ();

$button-colors: (
	"white":      (normal: $black $white,      hover: $global   $black),
	"light":      (normal: $black $white,      hover: $global   $black),
	"dark":       (normal: $white $black,      hover: $global   $black),
	"dim":        (normal: $black $white,      hover: $global   $black),
	"global":     (normal: $black $white,      hover: $white    $black),
	"mindcurv":   (normal: $black $white,      hover: $white    $black),
	"initions":   (normal: $white $initions,   hover: #FF9B14 $white),
	"wysiwyg":    (normal: $white $wysiwyg,    hover: #00C6F7 $white),
	"eccelerate": (normal: $white $eccelerate, hover: #224456 $white),
	"ccg":        (normal: $black $white,      hover: $white    $black),
	"igniti":     (normal: $white $igniti,     hover: #224456 $white),
	"ccx":        (normal: $white $ccx,        hover: #ff80ae    $white),
);

// Prefix for :root CSS variables

$variable-prefix:             mc-;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
	0: 0,
	1\/8: calc(var(--g) * 0.125),
	1\/4: calc(var(--g) * 0.25),
	1\/2: calc(var(--g) * 0.5),
	1: var(--g),
	2: calc(var(--g) * 2),
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-100;
$body-color:                $gray-900;
$body-text-align:           null;

// Links
//
// Style anchor elements.

$link-color:                              inherit;
$link-decoration:                         underline;
$link-hover-color:                        inherit;
$link-hover-decoration:                   none;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1344px,
  xxl: 1600px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
	xs: 90%,
	sm: 94%,
  lg: 96%,
  xl: 88%,
  xxl: 80%
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width:          var(--g);
$grid-row-columns:           6;

$gutters: $spacers !default;

// Container padding

$container-padding-x: calc(#{$grid-gutter-width} * .5);
$card-group-margin:   calc(#{$grid-gutter-width} * .5);

// Border Radius
//
$border-radius-lg: 24px;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      AvenirNext, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-serif:           Sentinel, serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-family-base:            $font-family-sans-serif;
$font-family-code:            $font-family-monospace;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$font-weight-base:            300;
$font-weight-bolder:          700;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              1.7;

$h1-font-size:                null; // $font-size-base * 2.5 !default;
$h2-font-size:                1.5em; // $font-size-base * 2 !default;
$h3-font-size:                1.3em; // $font-size-base * 1.75 !default;
$h4-font-size:                1.2em; // $font-size-base * 1.5 !default;
$h5-font-size:                1.1em; // $font-size-base * 1.25 !default;
$h6-font-size:                null; // $font-size-base !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
	sm: 14px,
) !default;

$headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family:        null !default;
$headings-font-style:         null !default;
$headings-font-weight:        700;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

$display-font-sizes: (
  1: clamp(36px, 7.5vw, 200px),
  2: clamp(32px, 5.35vw, 150px),
  3: clamp(30px, 4.55vw, 125px),
	4: clamp(30px, 3.4vw, 100px),
);

$display-font-weight: 700;
$display-line-height: clamp(1em, 4.5vw, 200px);

$lead-font-size:              1.2em;
$lead-font-weight:            400;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-font-size:         15px;
$input-btn-padding-y:         13px;
$input-btn-padding-x:         20px;
$input-btn-line-height:       1;

$input-btn-padding-y-lg:      20px;
$input-btn-padding-x-lg:      27px;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-size:               .75rem;
$btn-font-size-sm:            .625rem;

$btn-font-weight:             500;

$btn-border-radius:           50px;  // Round buttons
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;


// Forms

// $form-label-margin-bottom:              0.5rem !default;
$form-label-font-size:                  .75em;
$form-label-font-weight:                500;
$form-label-color:                      null;

$form-check-input-width:                em(20px);


$input-padding-y:                       em(13px);
$input-padding-x:                       em(20px);
$input-font-size:                       inherit;
$input-font-family:                     inherit;
// $input-font-weight:                     $font-weight-base !default;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-font-size-sm:                    $input-btn-font-size-sm !default;

// $input-padding-y-lg:                    $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-font-size-lg:                    $input-btn-font-size-lg !default;

$input-bg:                              #f0f0f0;
$input-disabled-bg:                     $gray-200;
$input-disabled-border-color:           null !default;

$input-color:                           $body-color !default;
$input-border-color:                    null;
$input-border-width:                    0;
// $input-box-shadow:                      $box-shadow-inset !default;

$input-border-radius:                   0;
// $input-border-radius-sm:                $border-radius-sm !default;
// $input-border-radius-lg:                $border-radius-lg !default;

// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// $input-placeholder-color:               $gray-600 !default;
// $input-plaintext-color:                 $body-color !default;

// $input-height-border:                   $input-border-width * 2 !default;

// Form validation

// $form-feedback-margin-top:          $form-text-margin-top !default;
// $form-feedback-font-size:           $form-text-font-size !default;
// $form-feedback-font-style:          $form-text-font-style !default;
// $form-feedback-valid-color:         $success !default;
$form-feedback-invalid-color:       #ff0063;

$form-feedback-icon-invalid-color:  $form-feedback-invalid-color;
$form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='12' height='12' fill='#{$form-feedback-icon-invalid-color}'><path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z'/></svg>");

// Navbar

$navbar-padding-y:                  calc(var(--g) * 0.75);

$navbar-nav-link-padding-x:         2vw;

$navbar-toggler-padding-y:          max(10px, 1vw);
$navbar-toggler-padding-x:          max(10px, 1vw);
// $navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-dark-color:                 $white;
$navbar-dark-hover-color:           $white;
$navbar-dark-active-color:          $white;
// $navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-dark-toggler-icon-bg:       unset;

$navbar-light-color:                $black;
$navbar-light-hover-color:          $black;
$navbar-light-active-color:         $black;
// $navbar-light-toggler-border-color: rgba($black, .1) !default;
$navbar-light-toggler-icon-bg:      unset;


// Offcanvas

$offcanvas-horizontal-width:        50%;
$offcanvas-transition-duration:     .3s !default;
// $offcanvas-border-color:            none;
// $offcanvas-border-width:            0;
// $offcanvas-title-line-height:       $modal-title-line-height !default;
$offcanvas-bg-color:                var(--mc-menu-bg, #{$black});
$offcanvas-color:                   var(--mc-menu-color, #{$white});
$offcanvas-box-shadow:              none;
// $offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
// $offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;

$border-color:                      currentColor;
