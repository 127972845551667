/**
 * two versions of contact teasers
 * 1. Standard Teaser for multiple teasers eg contacts page
 * 2. MCG Contact Teaser aka Halo Teaser (single / double) eg solution pages | max three contacts on a page
 * in work
 */


.std-contact {
	overflow: hidden;
	padding-bottom: 0;
	padding-top: 0!important;

	&:not(.theme-dark) {
		background: linear-gradient(90deg, #DFDBDA, #D0C4C3);
	}

	.contact-person-info {
		position: relative;
		z-index: 1;

		@include media-breakpoint-down(sm) {
			p {
				font-size: 13px;
			}
		}
	}

	.contact-person-image {
		height: auto;
		width: 60vw;

		@include media-breakpoint-up(sm) {
			width: 40vw;
		}
		@include media-breakpoint-up(lg) {
			max-width: 100%;
		}
	}
}

.contact-person-name {
	display: block;
	font-weight: 700;
}

.contact-person-image-container .contact-person-image {
	filter: drop-shadow(0 2px 60px rgba(31, 31, 30, 0.15));
}

.std-contact.theme-dark .contact-person-image-container .contact-person-image {
	filter: none;
}

// halo

.mcg-contact {
	background: linear-gradient(90deg, #DFDBDA, #D0C4C3);
	overflow: hidden;
	padding-bottom: 0;
	padding-top:  calc(var(--g) * 1.25)!important;

	.contact-person-info {
		color: $black;
	}

	.contact-person-cta {
		text-align: right;
	}
}

.mcg-contact-double-item {
	overflow: hidden;
}

@include media-breakpoint-up(md) {
	.mcg-contact + .mcg-contact {
		border-top: 1px solid $black;
	}
}

@include media-breakpoint-down(md) {
	.mcg-contact-double-item:first-of-type .row {
		border-bottom: 1px solid #000;
	}

	.mcg-contact-double-item:last-of-type .row {
		padding-top: 2em;
	}
}

// test
@include media-breakpoint-up(md) {
	.mcg-contact-double-item:first-of-type .row {
		padding-right: 1em;
	}
	.mcg-contact-double-item:last-of-type .row {
		padding-left: 1em;
	}
}

@include media-breakpoint-up(lg) {
	.mcg-contact-double-item:first-of-type .row {
		padding-right: 2em;
	}
	.mcg-contact-double-item:last-of-type .row {
		padding-left: 2em;
	}
}


/* image container */
.contact-person-image-container {
	max-width: 70vw;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1em 0 1em;
	position: relative;
}

.contact-person-image-container .mcg-ring {
	bottom: 0;
	left: 50%;
	max-width: 77%;
	position: absolute;
	transform: translate(-50%, 0);
	z-index: 0;
}

.mcg-contact .contact-person-image-container .contact-person-image {
	position: relative;
	z-index: 1;
}



@include media-breakpoint-up(sm) {

	.contact-person-container-wide .contact-person-image-container {
		margin-top: -50px;
	}
}

@include media-breakpoint-up(md) {

	.contact-person-image-container {
		max-width: 50vw;
	}

	.contact-person-container-wide .contact-person-image-container {
		margin-top: -100px;
	}

	.contact-person-image-container {
		max-width: 32.5vw;
	}

	.contact-person-container-wide .contact-person-info p {
		max-width: 75%;
	}
}

@include media-breakpoint-up(lg) {

	.contact-person-container-wide .contact-person-image-container {
		margin-top: -120px;
	}

	.contact-person-container-wide .contact-person-info {
		padding-left: calc(var(--g) * 2);
	}

	.contact-person-container-wide .contact-person-cta {
		padding-right: calc(var(--g) * 2);
	}
}
