[class*="theme-"] {
	color: var(--mc-color);
	background-color: var(--mc-bg);
}

// Generates classnames for `$theme-colors`, e.g.: `.theme-light`, `.theme-dark`
//
// Each theme has it's own `$button-colors` and some themes (like `.theme-dark)
// have special colors for the `.page-header`.
@each $name, $theme in $theme-colors {
	$btn-theme: map-get($button-colors, $name);

	.theme-#{$name} {
		--mc-bg:     #{nth($theme, 1)};
		--mc-color:  #{nth($theme, 2)};

		--mc-btn-bg:          #{nth(map-get($btn-theme, 'normal'), 1)};
		--mc-btn-color:       #{nth(map-get($btn-theme, 'normal'), 2)};
		--mc-btn-hover-bg:    #{nth(map-get($btn-theme, 'hover'), 1)};
		--mc-btn-hover-color: #{nth(map-get($btn-theme, 'hover'), 2)};

		$exceptions: 'dark', 'light', 'dim', 'white';

		@if index($exceptions, $name) {
			$theme: map-get($theme-colors, if($name == 'dark', 'light', 'dark'));

			.page-header {
				--mc-bg:         #{$global};
				--mc-menu-bg:    #{nth($theme, 1)};
				--mc-menu-color: #{nth($theme, 2)};
			}
		}
	}
}

.theme-dark .topline,
.theme-initions .topline,
.theme-wysiwyg .topline,
.theme-ccg .topline,
.theme-igniti .topline {
	opacity: .66;
}
