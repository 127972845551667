.megalinks {
	margin-left: -1.55vw;
}

.megalinks-link {
	display: block;
	width: fit-content;
	overflow: hidden;
	font-size: 25vw;
	line-height: 0.95;
	margin-top: -1rem;
	font-weight: 300;
	letter-spacing: -0.05em;
	text-decoration: none;

	&:hover {
		color: var(--mc-global);
	}

	@include media-breakpoint-up(sm) {
		font-size: 15.5vw;
	}
}
