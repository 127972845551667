@font-face {
	font-family: 'AvenirNext';
	src: url('../fonts/AvenirNext-Light.woff2');
	src: url('../fonts/AvenirNext-Light.woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../fonts/AvenirNext-Regular.woff2');
	src: url('../fonts/AvenirNext-Regular.woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../fonts/AvenirNext-Medium.woff2');
	src: url('../fonts/AvenirNext-Medium.woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../fonts/AvenirNext-Demi.woff2');
	src: url('../fonts/AvenirNext-Demi.woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AvenirNext';
	src: url('../fonts/AvenirNext-Bold.woff2');
	src: url('../fonts/AvenirNext-Bold.woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sentinel';
	src: url('../fonts/Sentinel-Light_Web.woff2');
	src: url('../fonts/Sentinel-Light_Web.woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sentinel';
	src: url('../fonts/Sentinel-BookItalic_Web.woff2');
	src: url('../fonts/Sentinel-BookItalic_Web.woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}
