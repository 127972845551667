/**
 *
 * component image header
 */
 .c-image-header {

	.c-image-header--headline-container {
		padding-bottom: calc(var(--g) * 3);
		padding-top: calc(var(--g) * 3);

		@media (max-width: 768px) {
			padding-bottom: calc(var(--g) * 4);
			padding-top: calc(var(--g) * 4);
		}

		.c-image-header--text {
			letter-spacing: 0.05em;
			filter: drop-shadow(0 2px 60px #1f1f1e26);

			h1, h2, h3, h4, h5, p {
				font-size: inherit;
				margin-bottom: 0;
			}
		}
	}

}
