.mindfeed-content {
	h2 {
		margin-bottom: calc(var(--g) * 0.5);
	}

	p + h3 {
		margin-top: var(--g);
	}

	ul:not([class]) {
		margin-bottom: calc(var(--g) * 0.5);

		> li {
			margin-bottom: calc(var(--g) * 0.25);
		}
	}
}
