/**
 * component: quote
 * additional image section for quote
 */

.quote {

	figure {
		margin: 0;

		@include make-row;
		justify-content: center;

		> * {
			@include make-col-ready;
			@include make-col(12);

			@include media-breakpoint-up(md) {
				@include make-col(10);
			}

			@include media-breakpoint-up(lg) {
				@include make-col(8);
			}
		}
	}

	blockquote {
		font-weight: 700;
		margin: 0;

		a {
			font-weight: inherit;
		}

		// additional sizes
		&.quote-size-medium {
			font-size: 24px;
			line-height: 28px;

			@include media-breakpoint-up(md) {
				font-size: 44px;
				line-height: 48px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 52px;
				line-height: 58px;
			}
		}

		&.quote-size-large {
			font-size: 28px;
			line-height: 34px;
			letter-spacing: 0.2px;

			@include media-breakpoint-up(md) {
				font-size: 56px;
				line-height: 64px;
				letter-spacing: 0.2px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 72px;
				line-height: 80px;
				letter-spacing: 0.4px;
			}
		}

		&.quote-size-xlarge {
			font-size: 38px;
			line-height: 40px;
			letter-spacing: 0.3px;

			@include media-breakpoint-up(md) {
				font-size: 76px;
				line-height: 80px;
				letter-spacing: 0.4px;
			}

			@include media-breakpoint-up(lg) {
				font-size: 96px;
				line-height: 100px;
				letter-spacing: 0.5px;
			}
		}
	}

	cite {
		font-style: normal;
	}

	.quoted {
		font-size: 10px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	.lavalamp {
		[data-lavalamp] {
			width: 100%;
			margin: 0%;
		}
	}
}

.zipfi {
	position: relative;
	justify-content: center;
	min-height: auto;

	&:before {
		content: " ";
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 2;
		height: 0;
		width: var(--g);
		background: transparent;
		border-bottom: var(--g) solid transparent;
		border-left: var(--g) solid white;
	}

	.portrait {
		background-color: transparent;

		img {
			width: 100%;
			height: calc(100vw - calc(var(--g)*2));
			object-fit: cover;
			vertical-align: bottom;
			border-radius: 50%;

		}
		@media all and (min-width: 576px) {
			img {
				height: calc(calc(100vw - calc(var(--g)*4))/3);
			}
		}

		@media all and (min-width: 84em) {
			img  {
				height: calc(calc(100vw - calc(var(--g)*6))/3);
			}
		}

		@media all and (min-width: 100em) {
			img  {
				height: calc(calc(100vw - calc(var(--g)*8))/3);
			}
		}
	}
}
