.lavalamp {
	max-width: 75vw;
	margin: 0 auto;
	mix-blend-mode: darken;

	[data-lavalamp] {
		aspect-ratio: 1;
		width: 150%;
		margin: -25%;
	}
}
