/**
 * Custom styles for the html video player ui, currently Chromium only
 */

 video::-webkit-media-controls-panel {
	background: none;
	height: min-content;
	align-self: end;
	backdrop-filter: blur(5px) brightness(0.6);
	flex: 0 1 auto;
	margin: 0 auto calc(var(--g) / 2);
	border-radius: 5em;
	min-width: 225px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1em;
}

video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-timeline {
	display: none;
}
