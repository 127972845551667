:root {
	view-transition-name: none;
}

// Enter animation
html:has(.teaser)::view-transition-new(*):only-child {
	animation-name: scale-in, -ua-mix-blend-mode-plus-lighter;
}

// Exit animation
html:has(.teaser)::view-transition-old(*):only-child {
	animation-name: scale-out, -ua-mix-blend-mode-plus-lighter;
}

@keyframes scale-out {
	to {
		opacity: 0;
		transform: scale(.8);
	}
}

@keyframes scale-in {
	from {
		opacity: 0;
		transform: scale(.8);
	}
}
